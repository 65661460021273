import { setJWT, getJWT } from 'utils/auth'
import { useSignMessage } from 'wagmi'
import Web3Token from 'web3-token'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { postAuthUser } from '@api/client'
import { TENANT_CONFIG, getTenantHost } from '@config'
import { User } from '@generated/generates'
import { useLogout } from '@hooks'
import useDisplayMobileSupportWarning from '@pages/auth/useDisplayMobileSupportWarning'
import useApplyCreditsRetroactively from './useApplyCreditsRetroactively'
import useMixpanel from './useMixpanel'

const useLogin = () => {
  useDisplayMobileSupportWarning()
  const hasJWT = !!getJWT()
  const { logout } = useLogout()
  const queryClient = useQueryClient()
  const { identify, peopleSet } = useMixpanel()
  const { isPending, signMessageAsync } = useSignMessage()

  const applyCreditsRetroactively = useApplyCreditsRetroactively()
  const handleSuccessfulLogin = (data: { token: string; user: User }) => {
    const { token, user } = data
    setJWT(token)
    queryClient.invalidateQueries({ queryKey: ['GetCategories'] })
    queryClient.invalidateQueries({ queryKey: ['GetCategoryQuests'] })
    // small delay to ensure jwt is present before applying credits
    applyCreditsRetroactively()
    identify(user?.id)
    peopleSet({
      hasEmailConnected: user?.emailAddress ? true : false
    })
  }

  const { mutate, isPending: isPendingUser } = useMutation({
    mutationFn: postAuthUser,
    onSuccess: handleSuccessfulLogin,
    onError: (data: unknown) => {
      logout()
      // @ts-ignore
      console.error('ERROR authenticating user: ', data?.response?.data?.message)
    }
  })

  const login = async () => {
    if (hasJWT) return
    try {
      const signedMessage = await Web3Token.sign(async (message: string) => await signMessageAsync({ message }), {
        domain: getTenantHost(),
        statement: `Connecting with ${TENANT_CONFIG.name} platform`
      })
      if (signedMessage) {
        mutate(signedMessage)
      }
    } catch (error) {
      logout()
      console.error('Error signing message: ', error)
    }
  }

  const isConnectingUser = isPending || isPendingUser

  return {
    isConnecting: isConnectingUser,
    login
  }
}

export default useLogin
