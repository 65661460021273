import { useCallback } from 'react'
import mixpanel from 'mixpanel-browser'

if (MIXPANEL_TOKEN) {
  mixpanel.init(MIXPANEL_TOKEN, { track_pageview: 'full-url' })
} else {
  console.error('Mixpanel token is not available')
}

const TRACKING_ACTIVE = Boolean(MIXPANEL_TOKEN)

type MixpanelProps = {
  [key: string]: string | number | boolean
}

interface UseMixpanel {
  identify: (id: string) => void
  alias: (id: string) => void
  track: (name: string, props?: MixpanelProps) => void
  trackLinks: (div: string, name: string, props?: MixpanelProps) => void
  register: (props: MixpanelProps) => void
  peopleSet: (props: MixpanelProps) => void
  peopleIncrement: (props: MixpanelProps) => void
}

const useMixpanel = (): UseMixpanel => {
  const identify = useCallback((id: string) => {
    if (TRACKING_ACTIVE) mixpanel.identify(id)
  }, [])

  const alias = useCallback((id: string) => {
    if (TRACKING_ACTIVE) mixpanel.alias(id)
  }, [])

  const track = useCallback((name: string, props?: MixpanelProps) => {
    if (TRACKING_ACTIVE) mixpanel.track(name, props)
  }, [])

  const trackLinks = useCallback((div: string, name: string, props?: MixpanelProps) => {
    if (TRACKING_ACTIVE) mixpanel.track_links(div, name, props)
  }, [])

  const register = useCallback((props: MixpanelProps) => {
    if (TRACKING_ACTIVE) mixpanel.register(props)
  }, [])

  const peopleSet = useCallback((props: MixpanelProps) => {
    if (TRACKING_ACTIVE) mixpanel.people.set(props)
  }, [])

  const peopleIncrement = useCallback((props: MixpanelProps) => {
    if (TRACKING_ACTIVE) mixpanel.people.increment(props)
  }, [])

  return {
    identify,
    alias,
    track,
    trackLinks,
    register,
    peopleSet,
    peopleIncrement
  }
}

export default useMixpanel
