import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import { useUpdateCurrentUserSettingsMutation } from '@generated/generates'
import { useViewer } from '@hooks'
import { useWeb3Modal } from '@web3modal/wagmi/react'

interface Props {
  callback?: () => void
}

const useLootdropOnboarding = ({ callback }: Props) => {
  const queryClient = useQueryClient()
  const { open: openConnectModal } = useWeb3Modal()
  const { viewer, isAuthenticated } = useViewer()
  const [slide, setSlide] = useState(0)
  const isFirstSlide = slide === 0
  const [isCompletingOnboarding, setIsCompletingOnboarding] = useState(false)
  const refreshUserQuery = () => queryClient.invalidateQueries({ queryKey: ['GetUser'] })
  const { mutateAsync: updateUserSettingsMutation } = useUpdateCurrentUserSettingsMutation(graphqlClient(), {
    onSuccess() {
      refreshUserQuery()
      callback && callback()
    }
  })

  const completeLootdropOnboarding = async () => {
    setIsCompletingOnboarding(true)
    try {
      await updateUserSettingsMutation({ isLootDropOnboardingCompleted: true })
    } catch (error) {
      console.error('Error completing Lootdrop onboarding: ', error)
    } finally {
      setIsCompletingOnboarding(false)
      refreshUserQuery()
    }
  }

  const handleClickActionButton = () => {
    const finalAction = isAuthenticated ? completeLootdropOnboarding : openConnectModal

    return isFirstSlide ? setSlide(1) : finalAction && finalAction()
  }

  return {
    slide,
    setSlide,
    isLootdropOnbardingCompleted: viewer?.isLootDropOnboardingCompleted,
    handleClickActionButton,
    isCompletingOnboarding
  }
}

export default useLootdropOnboarding
