import { useState } from 'react'
import { ContentHeader, ContentLayout, useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Box, useElementSize, useWindowEvent } from 'summon-ui/mantine'
import { LeaderboardViews } from '@features/leaderboard'

const LEADERBOARD_PAGE_TOP_SPACE = 50
const LEADERBOARD_PAGE_TOP_SPACE_TABLET = 100

const LeaderboardPage = () => {
  const t = useTranslation()
  const { ref, height: contentHeaderHeight } = useElementSize()
  const isMaxTablet = useMediaScreen({ type: 'max', breakpoint: 'tablet' })
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  const handleResize = () => {
    setWindowHeight(window.innerHeight)
  }
  useWindowEvent('resize', handleResize)

  return (
    <ContentLayout
      renderContentHeader={() => (
        <Box ref={ref}>
          <ContentHeader name={t('Leaderboard')} />
        </Box>
      )}
    >
      <Box
        h={
          windowHeight -
          contentHeaderHeight -
          (isMaxTablet ? LEADERBOARD_PAGE_TOP_SPACE_TABLET : LEADERBOARD_PAGE_TOP_SPACE)
        }
        className='overflow-hidden'
      >
        <LeaderboardViews />
      </Box>
    </ContentLayout>
  )
}

export default LeaderboardPage
