import { useNavigate } from 'react-router-dom'
import { useDisconnect } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'
import { deleteJWT } from '@utils/auth'

interface ReturnType {
  logout: () => void
}

const useLogout = (): ReturnType => {
  const { disconnectAsync } = useDisconnect()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const logout = () => {
    deleteJWT()
    localStorage.clear()
    queryClient.clear()
    disconnectAsync().then(() => {
      navigate('/')
    })
  }

  return { logout }
}

export default useLogout
