import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ContentHeader, ContentLayout, useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Box } from 'summon-ui/mantine'
import { TENANT_CONFIG } from '@config'
import { HomeViews } from '@features/home'
import { TutorialIds } from '@hocs/Tutorial/helpers.tutorial'
import { useRedeemables } from '@hooks'

const ConnectedHomePage = () => {
  const t = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isInventoryOpen = pathname === '/inventory'
  const isMobile = useMediaScreen({ breakpoint: 'mobile' })
  const { rewardsRedeemables } = useRedeemables({ active: true })
  const hasRedeemablesToClaim = rewardsRedeemables && rewardsRedeemables?.some((item) => item.redeemed === false)

  // Scroll to top on route change
  const MENU_ACTIONS = useMemo(
    () => [
      {
        name: 'Rewards',
        iconName: 'Shield01',
        action: () => navigate('/rewards'),
        withIndicator: !!hasRedeemablesToClaim,
        small: isMobile
      },
      {
        name: 'Avatar',
        iconName: 'Loading03',
        action: () => navigate('/inventory'),
        small: isMobile
      },
      {
        name: 'Crafting',
        iconName: 'ZapFast',
        action: () => navigate('/crafting'),
        small: isMobile,
        disabled: !TENANT_CONFIG.features.crafting
      }
    ],
    [rewardsRedeemables, isMobile]
  )

  const renderContentHeader = () => (
    <ContentHeader name={t('Home')}>
      {MENU_ACTIONS.filter((action) => !action.disabled).map((action) => {
        return action.name === 'Avatar' ? (
          <Box key={action.name} id={TutorialIds.Avatar}>
            <ContentHeader.Button {...action} />
          </Box>
        ) : (
          <Box key={action.name} id={TutorialIds.Rewards}>
            <ContentHeader.Button key={action.name} {...action} />
          </Box>
        )
      })}
    </ContentHeader>
  )

  return (
    <ContentLayout {...(!(isInventoryOpen && isMobile) ? { renderContentHeader } : {})}>
      <HomeViews />
    </ContentLayout>
  )
}

export default ConnectedHomePage
