import { Countdown, EnhancedPaper, LootdropProgress, TimeIndicator, useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import {
  alpha,
  Badge,
  Box,
  Flex,
  Image,
  NumberFormatter,
  Stack,
  Text,
  rgba,
  Title,
  useMantineTheme,
  Group
} from 'summon-ui/mantine'
import { weAreBetweenDates } from '@features/lootdrops/helpers'
import { LootDrop } from '@generated/generates'
import styles from './lootdropCard.module.css'

const LootdropCard = ({
  completionPercentage,
  startTime,
  endTime,
  name,
  description,
  participantCount,
  coverImage,
  rewardPoolInfo
}: LootDrop) => {
  const t = useTranslation()
  const { colors } = useMantineTheme()
  // active should be linked to progress bar
  const isActive = weAreBetweenDates(startTime, endTime)
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'mini' })
  const highlighted = isActive && completionPercentage

  const renderStyle = () => {
    const bgColor = highlighted ? rgba(colors.lime[8], 0.1) : 'transparent'
    const bgColorHover = highlighted ? rgba(colors.lime[9], 0.2) : rgba(colors.gray[4], 0.1)
    const borderColor = highlighted ? colors.lime[9] : colors.gray[7]

    return {
      '--bg-color': bgColor,
      '--border-color': borderColor,
      '--hover-bg-color': bgColorHover
    }
  }

  return (
    <EnhancedPaper withBg>
      <Stack p='lg' style={renderStyle()} className={styles.root}>
        <Flex gap={isMobile ? 'md' : 0} direction={isMobile ? 'row' : 'column'} justify={isMobile ? 'start' : 'center'}>
          {isMobile && (
            <Image
              src={coverImage}
              alt='lootdrop cover image'
              data-highlighted={highlighted}
              className={styles.root}
              radius='md'
              w={100}
            />
          )}
          <Group justify={isMobile ? 'center' : 'space-between'} align={isMobile ? 'start' : 'center'} gap='xs'>
            <Title order={3}>{name}</Title>
            {isActive ? (
              <Countdown
                from={new Date(endTime)}
                render={(humanFormattedCountdown) => (
                  <Badge variant='outline' fw='bolder' size='lg' color={highlighted ? 'lime.9' : 'gray'} c='white'>
                    {humanFormattedCountdown}
                  </Badge>
                )}
              />
            ) : (
              <TimeIndicator
                startTime={new Date(startTime)}
                revealCountdownOn={new Date()}
                endTime={new Date(endTime)}
                betweenStartEndTimeText={t('Open')}
                endTimeText={t('Ended')}
                render={({ indicator }) => (
                  <Badge fw='bolder' size='lg' color='gray.7' c='white'>
                    {indicator}
                  </Badge>
                )}
              />
            )}
          </Group>
        </Flex>
        <Flex gap='md'>
          {!isMobile && (
            <Box p='0' miw={145} h={145}>
              <Image
                src={coverImage}
                alt='lootdrop cover image'
                data-highlighted={highlighted}
                className={styles.root}
                radius='md'
                w={145}
              />
            </Box>
          )}
          <Stack>
            <Text lineClamp={2} size='lg' dangerouslySetInnerHTML={{ __html: description || '' }} />
            <Text tt='capitalize'>
              {t('participants')}: <NumberFormatter thousandSeparator value={participantCount} />
            </Text>
            {isActive && (
              <Group gap='sm'>
                <Badge size='lg' color={alpha('var(--mantine-color-white)', 0.1)}>
                  {rewardPoolInfo}
                </Badge>
                <Badge size='lg' color={alpha('var(--mantine-color-white)', 0.1)}>
                  <Flex align='center' gap='xs'>
                    {/* FIXME: this is temporary , move this to icons */}
                    <svg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M15.9236 4.44612L12.4512 1.11325C12.0434 0.719969 11.4898 0.5 10.9102 0.5H10.2716C10.1154 0.5 9.98004 0.608318 9.95748 0.758297C9.82386 1.69817 8.98742 2.39807 8 2.39807C7.01258 2.39807 6.17614 1.69817 6.04252 0.758297C6.01822 0.608318 5.8846 0.5 5.72668 0.5H5.08807C4.5102 0.5 3.95662 0.719969 3.54707 1.11325L0.0763557 4.44612C0.0277657 4.49278 0 4.5561 0 4.62276C0 4.68942 0.0277657 4.75274 0.0763557 4.7994L2.76616 7.38238C2.81475 7.42904 2.88069 7.4557 2.95011 7.4557C3.01952 7.4557 3.08547 7.42904 3.13406 7.38238L4.18568 6.37252V11.9601C4.18568 12.2584 4.43731 12.5 4.74794 12.5H11.2521C11.5627 12.5 11.8143 12.2584 11.8143 11.9601V6.37252L12.8659 7.38238C12.9145 7.42904 12.9805 7.4557 13.0499 7.4557C13.1193 7.4557 13.1853 7.42904 13.2338 7.38238L15.9236 4.7994C15.9722 4.75274 16 4.68942 16 4.62276C16 4.5561 15.9722 4.49278 15.9236 4.44612Z'
                        fill='#A6EF67'
                      />
                    </svg>
                    {t('Wearables')}
                  </Flex>
                </Badge>
              </Group>
            )}
          </Stack>
        </Flex>
        {completionPercentage != null && (
          <Box py='md'>
            <LootdropProgress value={completionPercentage || 0} />
          </Box>
        )}
      </Stack>
    </EnhancedPaper>
  )
}

export default LootdropCard
