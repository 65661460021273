import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useCartQuery } from '@api/modules/shopify/hooks/useCartQuery'
import { useCreateCartMutation } from '@api/modules/shopify/hooks/useCreateCartMutation'
import { Cart } from '@api/modules/shopify/types'

const CartContext = createContext<
  Cart & {
    refetch: () => void
    totalItemsInCart: number
    isCartOpen: boolean
    setIsCartOpen: (open: boolean) => void
  }
>({
  id: '',
  checkoutUrl: '',
  estimatedCost: {
    totalAmount: {
      amount: ''
    }
  },
  lines: [] as unknown as Cart['lines'],
  totalItemsInCart: 0,
  refetch: () => null,
  isCartOpen: false,
  setIsCartOpen: () => null
})

export function CartProvider({ children }: { children: ReactNode }) {
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [cartId, setCartId] = useState('')
  const createCart = useCreateCartMutation()
  const { data, refetch, isFetched } = useCartQuery({ id: cartId, refetchInterval: 10000 })

  async function getCart() {
    let localCartId = window.localStorage.getItem('shopify:cartId')

    if (localCartId) {
      localCartId = JSON.parse(localCartId) as string
      setCartId(localCartId)
      return
    }

    const newCart = await createCart.mutateAsync()

    if (newCart) {
      setCartId(newCart.id)

      window.localStorage.setItem('shopify:cartId', JSON.stringify(newCart.id))
    }
  }

  useEffect(() => {
    getCart()
  }, [])

  useEffect(() => {
    if (isFetched && !data) {
      window.localStorage.removeItem('shopify:cartId')
      getCart()
    }
  }, [isFetched, data])

  const value = useMemo(() => {
    if (!data) {
      const defaultCart = {
        id: '',
        checkoutUrl: '',
        estimatedCost: { totalAmount: { amount: '0' } },
        lines: { edges: [] }
      } as Cart
      return { ...defaultCart, refetch, totalItemsInCart: 0, isCartOpen, setIsCartOpen }
    }

    return {
      ...data,
      refetch,
      totalItemsInCart: data?.lines.edges.reduce((total, item) => (total += item.node.quantity), 0) ?? 0,
      isCartOpen,
      setIsCartOpen
    }
  }, [data, isCartOpen, setIsCartOpen])

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

export const useCart = () => {
  const value = useContext(CartContext)

  return value
}
