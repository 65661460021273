import { contractsType } from '@config/types'

const CONTRACTS_CONFIG: contractsType = {
  mainnet: {
    nft_gate: '0x',
    avatar: '0x023938455d7A9B90984E38d9069fA4b34345a63c',
    items: '0xD437b472d96C5F005CC013655494Acd10c19867C',
    level: '0xF473487C507fdCD4BF21b989512FaBE3Ad032CC3',
    marketplace: '0xD437b472d96C5F005CC013655494Acd10c19867C',
    marketplaceFees: '0x27fC323216BAeee0eB4F059fCA7a5877ee2B4281',
    paymaster: '0x989F2891B46E2760258B7Fe2FdceeCAfb9f06642',
    lootdrop: '0xB183AFc6938cbAee7Dc206975136E4f366E4f0D6',
    achievoWallet: '0x85f56764F58F595D08252b98942554bFB5Eea390'
  },
  testnet: {
    nft_gate: '0x',
    avatar: '0xbF26f57D85f5b0376627752e6dF79648b1937Aa0',
    items: '0x3ED1892F8DC1B5E3896DA5eF28Bf568CdEA2041b',
    level: '0x315FA8c7ed4Fc6900411D7A0064c0bab52261993',
    marketplace: '0xD437b472d96C5F005CC013655494Acd10c19867C',
    marketplaceFees: '0x27fC323216BAeee0eB4F059fCA7a5877ee2B4281',
    paymaster: '0x26CdD29E485bB47Af2B6c1a881A3569C0f1E9a1b',
    lootdrop: '0x1ce0F9a5838924fDA290010f3911fCe41FCec364',
    achievoWallet: '0x4d790f180C71029F983c0A70901E3DcA1aAe12f5'
  }
}

export default CONTRACTS_CONFIG
