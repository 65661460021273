import { useTranslation } from 'summon-ui/intl'
import { Flex, Stack, Box, Title } from 'summon-ui/mantine'
import LootdropCategoryCard from '@features/lootdrops/components/LootdropCategoryCard'
import { weAreBetweenDates } from '@features/lootdrops/helpers'
import { Category } from '@generated/generates'

const LISTS_TOP_MARGIN = 7

interface CategorizedCategories {
  upcoming: Category[]
  open: Category[]
}

interface Props {
  categories: Category[]
  lootdropSlug: string
}

const categorizeCategories = (categories: Category[]): CategorizedCategories => {
  const now = new Date()
  const upcoming = categories.filter((category) => new Date(category.startTime) > now)
  const open = categories.filter((category) => weAreBetweenDates(category.startTime, category.endTime))

  return { upcoming, open }
}

const LootdropCategoriesList = ({ categories, lootdropSlug }: Props) => {
  const t = useTranslation()
  const { open, upcoming } = categorizeCategories(categories)

  return (
    <Box>
      <Flex gap='md' direction='column'>
        {open && open.length > 0 && (
          <Stack gap={LISTS_TOP_MARGIN}>
            <Title order={2}>{t('Open')}</Title>
            {open.map((category) => (
              <LootdropCategoryCard key={category.id} category={category} lootdropSlug={lootdropSlug} isOpen />
            ))}
          </Stack>
        )}

        {upcoming && upcoming.length > 0 && (
          <Stack gap={LISTS_TOP_MARGIN}>
            <Title order={2}>{t('Upcoming')}</Title>
            {upcoming.map((category) => (
              <LootdropCategoryCard key={category.id} category={category} lootdropSlug={lootdropSlug} />
            ))}
          </Stack>
        )}
      </Flex>
    </Box>
  )
}

export default LootdropCategoriesList
