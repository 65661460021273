export enum SearchParams {
  OpenModal = 'OpenModal'
}

export enum QueryKey {
  Quest = 'quest',
  Blueprints = 'blueprints',
  Search = 'search',
  History = 'history',
  Meta = 'meta',
  Alerts = 'alerts',
  Inventory = 'inventory',
  User = 'user',
  UserAlerts = 'user_alerts',
  Signature = 'signature'
}

export enum FileExtension {
  Json = 'json',
  Png = 'png',
  Svg = 'svg',
  Jpg = 'jpg',
  Jpeg = 'jpeg',
  Gif = 'gif',
  Webp = 'webp',
  Mp4 = 'mp4',
  Glb = 'glb',
  Gltf = 'gltf'
}

export enum CameraMode {
  Free = 'FREE',
  Head = 'HEAD',
  Face = 'FACE',
  Top = 'TOP',
  Bottom = 'BOTTOM',
  Feet = 'FEET'
}

export enum LocalStorageKey {
  IsOnboardingAllowed = 'isOnboardingAllowed',
  UserId = 'userId'
}

export const CDN_URL = 'https://inventory-assets.summon.xyz/avatar'
