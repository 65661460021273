import { SetStateAction, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Spinner, Icon } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Button, Stack, Text, Paper, Group, Divider, Title, Box } from 'summon-ui/mantine'
import { graphqlClient } from '@api/config'
import {
  useGetCraftingRecipesQuery,
  CraftingRecipe,
  CraftingMaterial,
  useGetUserCraftingMaterialsQuery,
  useCraftItemMutation,
  useGetUserQuery
} from '@generated/generates'
import MaterialSlot from '../MaterialSlot'
import BlueprintCard from './BlueprintCard'
import BlueprintMaterialSlot from './BlueprintMaterialSlot'

const Blueprints = () => {
  const t = useTranslation()
  const navigate = useNavigate()
  const { data: user } = useGetUserQuery(graphqlClient())
  const { data: recipesData, isLoading, error } = useGetCraftingRecipesQuery(graphqlClient())
  const [selectedRecipe, setSelectedRecipe] = useState<CraftingRecipe | null>(null)
  const { data: userMaterialsData } = useGetUserCraftingMaterialsQuery(graphqlClient())
  const { mutateAsync: craftItem, isPending: isCrafting, isSuccess } = useCraftItemMutation(graphqlClient())

  const ownedMaterials =
    userMaterialsData?.viewer?.craftingMaterials.filter((material: { count: any }) => material.count) || []
  const isCraftingEnabled = selectedRecipe?.requiredMaterials.every((m1) => {
    const ownedMaterial = ownedMaterials.find((material: { id: string }) => material.id === m1.id)
    return ownedMaterial !== undefined && ownedMaterial.count >= m1.count
  })

  const userLevel = user?.viewer?.level

  const ActionButtons = () => (
    <Stack>
      <Divider />
      <Group justify='space-between' w='100%'>
        {selectedRecipe && !isSuccess ? (
          <Button variant='outline' onClick={() => setSelectedRecipe(null)} w='100px'>
            {t('Back')}
          </Button>
        ) : (
          <Button variant='outline' onClick={() => navigate('/')} w='100px'>
            {t('Close')}
          </Button>
        )}
        <Button
          disabled={!selectedRecipe || !isCraftingEnabled || isSuccess}
          onClick={() => craftItem({ recipeId: selectedRecipe?.id as string })}
          loading={isCrafting}
          w='100px'
        >
          {t('Craft')}
        </Button>
      </Group>
    </Stack>
  )

  if (selectedRecipe && isSuccess) {
    return (
      <Stack justify='space-between' className='h-[calc(100vh-80px)]'>
        <Stack h='100%' align='center' gap='md' justify='center'>
          <Box mt='md'>
            <Title order={3} ta='center'>
              {t('Success')}
            </Title>
            <Text ta='center'>
              {t('Your')} {selectedRecipe.name} {t('was successfully crafted')}.
              <br />
              {t('Close this menu and check it out')}.
            </Text>
          </Box>
        </Stack>
        <ActionButtons />
      </Stack>
    )
  }

  return (
    <Stack justify='space-between'>
      <div className='flex h-[calc(100vh-80px)] w-full flex-col justify-between gap-2 overflow-auto'>
        <>
          {!!error && <Text>{t('Something wrong happened')}...</Text>}

          {!selectedRecipe && isLoading && (
            <Box h={400}>
              <Spinner isFull />
            </Box>
          )}

          {!selectedRecipe && !isLoading && (
            <>
              {userLevel === 0 ? (
                <Stack h='100%' ta='center' justify='center' align='center' gap='0'>
                  <Icon name='ZapFast' c='red' size='xl' />
                  <Title order={3}>{t('Unlock Crafting at Level 1')}</Title>
                  <Text ta='center' maw={300}>
                    {t('Head over to the quest page to continue your journey to Level 1')}
                  </Text>
                </Stack>
              ) : (
                <Box p='sm' className='overflow-y-auto'>
                  <Stack gap='xs'>
                    <Text>{t('Recipes')}</Text>
                    <Stack gap='sm'>
                      {recipesData?.craftingRecipes?.map((recipe: SetStateAction<CraftingRecipe | null>) => (
                        // @ts-ignore
                        <BlueprintCard key={recipe.id} recipe={recipe} onClick={() => setSelectedRecipe(recipe)} />
                      ))}
                    </Stack>
                  </Stack>
                </Box>
              )}
            </>
          )}
        </>

        {!!selectedRecipe && (
          <Box p='sm'>
            <Text mb='sm'>
              <Group gap='0'>
                <button onClick={() => setSelectedRecipe(null)}>{t('Recipes')}</button>
                <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M6 12L10 8L6 4'
                    stroke='#D0D5DD'
                    strokeWidth='1.33333'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                {selectedRecipe.name}
              </Group>
            </Text>

            <BlueprintCard recipe={selectedRecipe} />

            <Stack gap='sm' mt='md'>
              <Text>{t('Required Materials')}</Text>
              {selectedRecipe?.requiredMaterials
                .reduce(
                  (result: CraftingMaterial[][], value, index, array) => (
                    index % 2 === 0 ? result.push(array.slice(index, index + 2)) : null, result
                  ),
                  []
                )
                .map((couple) => (
                  <Group key={couple[0].id} preventGrowOverflow={false} gap='sm' wrap='nowrap' className='relative'>
                    <Paper withBorder radius='md' className='w-1/2'>
                      {couple[0] && (
                        <BlueprintMaterialSlot
                          image={couple[0].imageUrl}
                          name={couple[0].name}
                          count={couple[0].count}
                          areConditionsMet={
                            (userMaterialsData?.viewer?.craftingMaterials.find(
                              (material: { id: string }) => material.id === couple[0].id
                            )?.count as number) >= couple[0].count
                          }
                        />
                      )}
                    </Paper>

                    <div className='bg-white absolute right-[45%] top-[35%] rounded-full border border-gray-2 p-3'>
                      <Icon name='ZapFast' />
                    </div>

                    <Paper withBorder radius='md' className='w-1/2'>
                      {couple[1] && (
                        <BlueprintMaterialSlot
                          image={couple[1].imageUrl}
                          name={couple[1].name}
                          count={couple[1].count}
                          areConditionsMet={
                            (userMaterialsData?.viewer?.craftingMaterials.find(
                              (material: { id: string }) => material.id === couple[1].id
                            )?.count as number) >= couple[1].count
                          }
                        />
                      )}
                    </Paper>
                  </Group>
                ))}
              <Text>{t('Material Inventory')}</Text>

              {ownedMaterials.length ? (
                <Group gap='md'>
                  {ownedMaterials?.map(({ id, count, imageUrl, name }) => {
                    return <MaterialSlot key={id} image={imageUrl} count={count} name={name} />
                  })}
                </Group>
              ) : (
                <Paper withBorder radius='md' mt='sm' mih={140}>
                  <Stack mih={140} justify='center' align='center'>
                    <Text ta='center' px='xs'>
                      {t("You don't have any material in your inventory yet")}
                    </Text>
                  </Stack>
                </Paper>
              )}
            </Stack>
          </Box>
        )}
        <ActionButtons />
      </div>
    </Stack>
  )
}

export default Blueprints
