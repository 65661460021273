import { useGqlQuery } from '@api/gql/gqlServices'
import { GetNextCategorySuggestedDocument, GetNextCategorySuggestedQuery } from '@generated/generates'

const useNextCategorySuggested = () => {
  const { data: nextCategoryData, isLoading: isNextCategoryLoading } = useGqlQuery<GetNextCategorySuggestedQuery>(
    GetNextCategorySuggestedDocument
  )

  return {
    nextSuggestedCategory: nextCategoryData?.viewer?.currentLevelInfo?.nextCategorySuggested,
    isNextCategoryLoading
  }
}

export default useNextCategorySuggested
