import { Ref, useState } from 'react'
import { showNotification } from 'summon-ui'
import { useQueryClient } from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import { useEquipInventoryItemsMutation } from '@generated/generates'
import { useEquipItems } from '@hooks'

const useEquipInventory = (ref: Ref<HTMLCanvasElement>, onSuccess: () => void) => {
  const queryClient = useQueryClient()
  const [isEquipSuccess, setIsEquipSuccess] = useState(false)
  const { equipItemsPayload } = useEquipItems({})

  const { mutateAsync: equipInventoryItemsBatch, isPending: isEquipping } = useEquipInventoryItemsMutation(
    graphqlClient(),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries({ queryKey: ['GetInventoryItems'] })
        queryClient.invalidateQueries({ queryKey: ['GetInventoryItemsByIds'] })
        setIsEquipSuccess(true)
        onSuccess()
      },
      onError: (data: unknown) => {
        // @ts-ignore
        console.error('ERROR equipping inventory Items: ', data?.response?.data?.message)
        showNotification({
          variant: 'danger',
          message: 'ERROR equipping inventory Items'
        })
      }
    }
  )

  const equipInventory = async () => {
    equipInventoryItemsBatch({
      payload: equipItemsPayload
    })
  }

  return { isEquipping, isEquipSuccess, setIsEquipSuccess, equipInventory }
}

export default useEquipInventory
