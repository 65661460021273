import { arbitrum, mainnet, mantle, zkSync, zkSyncSepoliaTestnet, mantleTestnet } from 'wagmi/chains'
import { ConfigType } from '@config/types'
import { SlotType } from '@modules/inventory/hooks/useAvatarStore'
import { isStaging } from '@utils/isStaging'
import { SummonWallets } from '@utils/wallets'
import { ArtStyleEnum, AssetsPrefix, LevelUpVersion, Tenant } from '../enum'

const API_VERSION = 'v1/xps'

const DEFAULT_CONFIG: ConfigType = {
  name: Tenant.G7,
  content: {
    login: {
      title: 'Game7 nation',
      description:
        'Our gaming nation is a radical experiment in a meritocratic governance, where power, status, influence and rewards are distributed to those who make the most valuable contributions to the Game7 society.'
    }
  },
  assetsPrefix: AssetsPrefix.G7,
  artStyle: ArtStyleEnum.Art3D,
  projectId: 'bb937519e589cbe6aa69f2e870b24639',
  discordClientId: '1094278851091251280',
  network: isStaging ? zkSyncSepoliaTestnet : zkSync,
  supportedChains: isStaging
    ? [zkSyncSepoliaTestnet, mantleTestnet, mantle, zkSync, arbitrum, mainnet]
    : [mantle, zkSync, arbitrum, mainnet],
  levelVersion: LevelUpVersion.V2,
  termsConfig: {
    provider: Tenant.G7
  },
  isDarkMode: true,
  api: {
    staging: {
      rest: `https://staging-g7-api.summon.xyz/${API_VERSION}`,
      graphql: `https://staging-g7-api.summon.xyz/${API_VERSION}/graphql`,
      tenantKey: '355947552633258069'
    },
    staging2: {
      rest: `https://staging-g7-api.summon.xyz/${API_VERSION}`,
      graphql: `https://api-prisma.summon-labs.com/${API_VERSION}/xps/graphql`,
      tenantKey: '355947552633258069'
    },
    production: {
      rest: `https://g7p.io/${API_VERSION}`,
      graphql: `https://g7p.io/${API_VERSION}/graphql`,
      tenantKey: '355730745719783510'
    },
    shopify: {
      storeUrl: 'https://e3a8ea.myshopify.com/api/2023-10/graphql.json',
      apiToken: '96cc5130b660593b671a21a1ef636b51',
      hero: {
        title: 'Game7 swag: Level up your style!',
        subtitle: 'Discover exclusive and rare swag from the Game7 ecosystem.'
      }
    }
  },
  features: {
    onChainItems: true,
    dnaTraits: true,
    crafting: false,
    proposals: false,
    leaderboard: true,
    lootdrops: true,
    enableWeb2Auth: false,
    connect: {
      displayBar: true
    },
    enableShareAvatar: true,
    enableEditAvatar: true,
    warningMobileSupport: false,
    enableLeaderboardCampaigns: true,
    walletSettings: {
      solana: true
    },
    enableQuestPageCustomBgImg: true
  },
  meta: {
    title: 'Game7: A Gaming Nation',
    description: 'A Gaming Nation',
    ogTitle: 'Game7: A Gaming Nation',
    ogDescription:
      'Our gaming nation is a radical experiment in a meritocratic governance, where power, status, influence and rewards are distributed to those who make the most valuable contributions to the Game7 society.',
    ogImage: 'open-graph-image.png'
  },
  analytics: {
    googleTag: 'G-J1HK4FF7LG',
    luckyOrangeTag: 'c939bd01'
  },

  wallets: [
    SummonWallets.metamask,
    SummonWallets.walletConnect,
    SummonWallets.coinbaseWallet,
    SummonWallets.jambo,
    SummonWallets.trust,
    SummonWallets.okx,
    SummonWallets.zerion,
    SummonWallets.bybit
  ],
  sortedSlots: [
    SlotType.Legwear,
    SlotType.Eyewear,
    SlotType.Footwear,
    SlotType.Headwear,
    SlotType.Tops,
    SlotType.Outerwear
  ],
  urlLinks: {
    wiki: 'https://game7.gitbook.io/copy-of-game7-wiki',
    support: 'https://discord.com/channels/902795219781685299',
    lootDropTerms: 'https://docs.google.com/document/d/16uhrAIyq3ejgN5yZzA2CSgGP5IO2QxDFyvsv_5G4yK8/edit'
  },
  lang: 'en',
  uiTheme: 'carbon'
}

export default DEFAULT_CONFIG
