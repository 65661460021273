import { useEffect, useState } from 'react'
import type { FC, ReactNode } from 'react'
import { useTranslation } from 'summon-ui/intl'
import {
  Center,
  Loader,
  Text,
  Flex,
  Stack,
  useMantineTheme,
  useIntersection,
  Box,
  useMediaQuery,
  Paper,
  useMantineColorScheme
} from 'summon-ui/mantine'
import { Category } from '@generated/generates'

const CARD_WIDTH = 370
const CARD_HEIGHT = 328
const MIN_ITEMS_MOBILE = 8
const MIN_ITEMS_DESKTOP = 21

interface Props {
  isLoading: boolean
  cards: Category[]
  renderCardElement: (category: Category) => ReactNode
}

const QuestCategoryCardGrid: FC<Props> = ({ isLoading, cards, renderCardElement }) => {
  const t = useTranslation()
  const [visible, setVisible] = useState(0)
  const { breakpoints } = useMantineTheme()
  const { colorScheme } = useMantineColorScheme()
  const [categories, setCategories] = useState<Category[]>([])
  const { ref, entry } = useIntersection({ threshold: 1 })
  const isIntersecting = entry && entry.isIntersecting
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.lg})`)

  useEffect(() => {
    setCategories(cards.length > visible ? cards.slice(0, visible) : cards)
  }, [cards, visible])

  useEffect(() => {
    if (isIntersecting) {
      const items = isMobile ? MIN_ITEMS_MOBILE : MIN_ITEMS_DESKTOP
      setVisible((s) => s + items)
    }
  }, [isIntersecting, isMobile])

  useEffect(() => {
    setVisible(isMobile ? MIN_ITEMS_MOBILE : MIN_ITEMS_DESKTOP)
  }, [isMobile])

  const renderCardList = () => (categories.length > 0 ? categories.map(renderCardElement) : null)

  const renderLoadingCards = () => {
    return [1, 2, 3, 4, 5, 6].map((el) => (
      <Paper
        key={el}
        className='w-min-[300px] animate-pulse'
        h={CARD_HEIGHT}
        radius='lg'
        bg={colorScheme === 'dark' ? 'dark.3' : 'gray.3'}
      />
    ))
  }

  return (
    <Box>
      <Box
        style={{
          display: 'grid',
          gridGap: '1.5rem',
          gridTemplateColumns: `repeat(auto-fill, minmax(${CARD_WIDTH}px, 1fr))`
        }}
      >
        {isLoading ? renderLoadingCards() : renderCardList()}
      </Box>
      {!isLoading && !categories.length && (
        <Flex w='100%' align='center' justify='center'>
          <Stack py={20}>
            <Text ta='center'>{t('Oops! There are no results')}</Text>
          </Stack>
        </Flex>
      )}
      {!isLoading && visible === categories.length && (
        <Center ref={ref} w='100%'>
          <Loader />
        </Center>
      )}
    </Box>
  )
}

export default QuestCategoryCardGrid
