import { motion } from 'framer-motion'
import { BackgroundImage, Text } from 'summon-ui/mantine'

type CompletedAnimationProps = {
  data: number
  show: boolean
}
const variants = {
  open: { opacity: 1, transform: 'translate(-50%, -100%)' },
  closed: { opacity: 0, transform: 'translate(-50%, -170%)' }
}
const CompletedAnimation = ({ data, show }: CompletedAnimationProps) => {
  return (
    <motion.div className='absolute top-1/2 left-1/2 ' animate={show ? 'open' : 'closed'} variants={variants}>
      <Text className='font-bold text-lg'>
        <BackgroundImage src='/images/completed-bg.svg'>+{data} XP</BackgroundImage>
      </Text>
    </motion.div>
  )
}

export default CompletedAnimation
