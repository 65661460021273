import { useDebouncedState } from 'summon-ui/mantine'
import { useGqlQuery } from '@api/gql/gqlServices'
import {
  GetReferralLeaderboardCurrentUserDocument,
  GetReferralLeaderboardCurrentUserQuery,
  GetReferralLeaderboardDocument,
  GetReferralLeaderboardQuery,
  Maybe,
  ReferralLeaderboard
} from '@generated/generates'
import { useViewer } from '@hooks'
import { sliceAddress } from '@utils/strings'

const useReferralsLeaderboard = (pageSize: number, minReferrals?: number) => {
  const { viewer } = useViewer()
  const [currentPage, setCurrentPage] = useDebouncedState(1, 200)
  const { data, isLoading, status, refetch } = useGqlQuery<GetReferralLeaderboardQuery>(
    GetReferralLeaderboardDocument,
    {
      limit: pageSize,
      offset: (currentPage - 1) * pageSize,
      minReferrals
    }
  )

  const { data: userLeaderboardData } = useGqlQuery<GetReferralLeaderboardCurrentUserQuery>(
    GetReferralLeaderboardCurrentUserDocument,
    {},
    { enabled: !!viewer }
  )

  const handleRetrievePage = (page: number) => {
    setCurrentPage(page)
  }

  const shallDisplayUserInfo =
    userLeaderboardData && !!userLeaderboardData.getReferralLeaderboardCurrentUser && minReferrals === undefined

  const topThreeLeaderboardUsers = [
    { ...data?.getReferralLeaderboard?.[1], position: 2 },
    { ...data?.getReferralLeaderboard?.[0], position: 1 },
    { ...data?.getReferralLeaderboard?.[2], position: 3 }
  ]
  const referralsCount = userLeaderboardData?.getReferralLeaderboardCurrentUser?.referredUserIds?.length || 0
  return {
    isLoading,
    status,
    refetch,
    referralsCount,
    topThreeLeaderboardUsers,
    leaderboard: data?.getReferralLeaderboard
      ? buildUpLeaderboardTableData([
          ...(shallDisplayUserInfo
            ? [userLeaderboardData.getReferralLeaderboardCurrentUser as ReferralLeaderboard]
            : []),
          ...(data.getReferralLeaderboard as ReferralLeaderboard[])
        ])
      : [],

    currentPage,
    navigateToPage: handleRetrievePage,
    isUserOnLeaderboard: shallDisplayUserInfo
  }
}

export default useReferralsLeaderboard

export interface ReferralLeaderboardTableElement {
  rank: Maybe<number> | undefined
  member: {
    walletAddress: Maybe<string> | undefined
    name: Maybe<string> | undefined
    avatarUrl: Maybe<string> | undefined
    twitter: Maybe<string> | undefined
  }
  users: number
  credits: Maybe<number> | undefined
  referralCode: Maybe<string> | undefined
}

const buildUpLeaderboardTableData = (
  leaderboard: Array<ReferralLeaderboard>
): Array<ReferralLeaderboardTableElement> => {
  const updatedLeaderboard = [
    ...leaderboard.map((user, index) => ({
      rank: index + 1,
      member: {
        avatarUrl: user?.avatarUrl,
        walletAddress: user.walletAddress,
        name: user.username || user.firstName || sliceAddress(user.walletAddress as string, 5),
        twitter: user?.twitterUsername
      },
      users: user.referredUserIds?.length || 0,
      credits: Math.floor(user.creditsAmount || 0),
      referralCode: user?.referralCode
    }))
  ]

  return updatedLeaderboard
}
