import { ReactNode, createContext, useContext, useMemo, useState } from 'react'
import { ProductCollectionSortKeys } from '@api/modules/shopify/enums'

export type FiltersType = {
  sortOption: ProductCollectionSortKeys
  collection?: string
}

export const defaultFilterData = {
  sortOption: ProductCollectionSortKeys.BEST_SELLING
}

const FilterContext = createContext<{
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  filters: FiltersType
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>
}>({
  isOpen: false,
  setIsOpen: () => null,
  filters: defaultFilterData,
  setFilters: () => null
})

export function FilterProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false)
  const [filters, setFilters] = useState<FiltersType>(defaultFilterData)

  const value = useMemo(() => ({ isOpen, setIsOpen, filters, setFilters }), [isOpen, setIsOpen, filters, setFilters])

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
}

export const useFilter = () => {
  const value = useContext(FilterContext)

  return value
}
