import { ContentHeader, ContentLayout } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { LootdropsViews } from '@features/lootdrops'

const LootdropsPage = () => {
  const t = useTranslation()

  return (
    <ContentLayout renderContentHeader={() => <ContentHeader name={t('LootDrop')} />}>
      <LootdropsViews />
    </ContentLayout>
  )
}

export default LootdropsPage
