import { TENANT_API } from '@config'
import { getJWT } from '@utils/auth'

const TWITTER_AUTH = `${TENANT_API.rest}/integration/twitter/login`

export const handleTwitterAuth = () => {
  const page = window?.location?.href
  const jwt = getJWT()
  const twitterAuthUrl = `${TWITTER_AUTH}?accessToken=${jwt}&returnUrl=${page}`

  window.open(twitterAuthUrl, '_self')
}
