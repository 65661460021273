import { Position, StylesObj } from '@reactour/tour'

export enum TutorialIds {
  Avatar = 'action-button_Avatar',
  Rewards = 'action-button_Rewards',
  Quests = 'nav-button_Quests',
  Lootdrop = 'nav-button_Lootdrop',
  Credits = 'nav-button_Credits',
  Leaderboard = 'nav-button_Leaderboard',
  Marketplace = 'nav-button_Marketplace',
  ProgressCard = 'element_Progress_Card',
  SuggestedQuestCard = 'element_Suggested_Category_card'
}

enum TutorialSelectors {
  Avatar = `#${TutorialIds.Avatar}`,
  Rewards = `#${TutorialIds.Rewards}`,
  Quests = `#${TutorialIds.Quests}`,
  Lootdrop = `#${TutorialIds.Lootdrop}`,
  Credits = `#${TutorialIds.Credits}`,
  Leaderboard = `#${TutorialIds.Leaderboard}`,
  Marketplace = `#${TutorialIds.Marketplace}`,
  ProgressCard = `#${TutorialIds.ProgressCard}`,
  SuggestedQuestCard = `#${TutorialIds.SuggestedQuestCard}`
}

const TUTORIAL_CONF = {
  avatar: {
    index: 0,
    id: TutorialIds.Avatar,
    selector: TutorialSelectors.Avatar
  },
  rewards: {
    index: 1,
    id: TutorialIds.Rewards,
    selector: TutorialSelectors.Rewards
  },
  quests: {
    index: 2,
    id: TutorialIds.Quests,
    selector: TutorialSelectors.Quests
  },
  lootdrop: {
    index: 3,
    id: TutorialIds.Lootdrop,
    selector: TutorialSelectors.Lootdrop
  },
  credits: {
    index: 4,
    id: TutorialIds.Credits,
    selector: TutorialSelectors.Credits
  },
  leaderboard: {
    index: 5,
    id: TutorialIds.Leaderboard,
    selector: TutorialSelectors.Leaderboard
  },
  marketplace: {
    index: 6,
    id: TutorialIds.Marketplace,
    selector: TutorialSelectors.Marketplace
  },
  progressCard: {
    index: 7,
    id: TutorialIds.ProgressCard,
    selector: TutorialSelectors.ProgressCard
  },
  suggestedQuestCard: {
    index: 8,
    id: TutorialIds.SuggestedQuestCard,
    selector: TutorialSelectors.SuggestedQuestCard
  }
}

const STEPS = [
  {
    selector: TUTORIAL_CONF.avatar.selector,
    content:
      'Here’s where you can find gear to further customize your avatar. As you complete quests and level-up, you’ll win rare and exciting gear.',
    padding: { popover: 20 }
  },
  {
    selector: TUTORIAL_CONF.rewards.selector,
    content: 'After you complete quests and campaigns, come here to claim and reveal special rewards.',
    padding: { popover: 20 }
  },
  {
    selector: TUTORIAL_CONF.quests.selector,
    padding: { popover: 20 },
    content:
      'This tab is where you’ll spend most of your time. It will show you all the dozens of opportunities you have to explore games and win rewards.',
    position: 'right' as Position
  },
  {
    selector: TUTORIAL_CONF.lootdrop.selector,
    padding: { popover: 20 },
    content:
      'LootDrops are a special type of multi-week quest that come with the potential to win significant rewards, if you stay committed throughout the quest.',
    position: 'right' as Position
  },
  {
    selector: TUTORIAL_CONF.credits.selector,
    padding: { popover: 20 },
    content:
      'Visit the Credits section of the dashboard to learn all the ways you can win credits during this limited-time campaign.',
    position: 'right' as Position
  },
  {
    selector: TUTORIAL_CONF.leaderboard.selector,
    padding: { popover: 20 },
    content: 'The leaderboard will show you how much XP you’ve collected compared to your fellow players.',
    position: 'right' as Position
  },
  {
    selector: TUTORIAL_CONF.marketplace.selector,
    padding: { popover: 20 },
    content:
      'If you’re looking for that perfect avatar wearable, but haven’t won it yet, you can check out the marketplace to see if one is for sale.',
    position: 'right' as Position
  },
  {
    selector: TUTORIAL_CONF.progressCard.selector,
    padding: { popover: 20 },
    content: 'This will tell you how much XP and how many required quests you need to reach the next level.',
    position: 'right' as Position
  },
  {
    selector: TUTORIAL_CONF.suggestedQuestCard.selector,
    padding: { popover: 20 },
    content: 'Now you know the platform, we recommend starting here…welcome!',
    position: 'right' as Position
  }
]

const buildTour = (locatiopPathName: string) => {
  const isHomePage = locatiopPathName === '/'
  const tour = isHomePage
    ? { steps: STEPS }
    : {
        steps: [
          STEPS[TUTORIAL_CONF.quests.index],
          STEPS[TUTORIAL_CONF.lootdrop.index],
          STEPS[TUTORIAL_CONF.credits.index],
          STEPS[TUTORIAL_CONF.leaderboard.index],
          STEPS[TUTORIAL_CONF.marketplace.index]
        ]
      }
  return tour
}

const buildStyles = (isDarkMode: boolean) => ({
  popover: (base: StylesObj) => ({
    ...base,
    ...{
      '--reactour-accent': 'var(--mantine-color-primary-5)'
    },
    ...(isDarkMode
      ? {
          backgroundColor: 'var(--mantine-color-dark-7)',
          opacity: 0.85,
          border: '1px solid var(--mantine-color-dark-6)',
          color: 'white'
        }
      : {})
  })
})

export { buildTour, STEPS, buildStyles }
