import { Outlet } from 'react-router-dom'
import { Spinner, DashboardLayout as SummonUiDashboardLayout } from 'summon-ui'
import { BackgroundImage, Box, Center, Stack, useDisclosure } from 'summon-ui/mantine'
import { useAccount } from 'wagmi'
import { ConnectButton, ProfileMenu } from '@components'
import { withAuth } from '@hocs'
import { useViewer } from '@hooks'
import BottomNavigationMenu from './BottomNavigationMenu'
import NavigationMenu from './NavigationMenu'
import UserSection from './UserSection'
import { DEFAULT_AVATAR_IMAGE } from './constants'
import useCalculateBg from './useCalculateBackground'
import useErrorCode from './useErrorCode'
import useStartTutorial from './useStartTutorial'

type DashboardLayoutProps = {
  isLoading?: boolean
}
const DashboardLayout = ({ isLoading }: DashboardLayoutProps) => {
  const bg = useCalculateBg()
  const { address } = useAccount()
  const { viewer, isAuthenticated } = useViewer()
  const [isProfileMenuOpen, { toggle: toggleProfileMenu }] = useDisclosure(false)
  useErrorCode()
  useStartTutorial(viewer?.hasCompletedTutorial, viewer?.isOnboardingCompleted)

  const accountName = viewer?.username || (viewer?.firstName ? viewer?.firstName + ' ' + viewer?.lastName : '')

  const renderNavigationMenu = ({
    isMinimal,
    closeMobileMenu
  }: {
    isMinimal: boolean
    closeMobileMenu: () => void
  }) => (
    <Stack gap='sm' align='center' w='100%' onClick={closeMobileMenu}>
      <NavigationMenu isSmall={isMinimal} />
    </Stack>
  )

  const renderBottomMenu = ({ isMinimal, closeMobileMenu }: { isMinimal: boolean; closeMobileMenu: () => void }) => {
    return isAuthenticated && !isLoading ? (
      <>
        <ProfileMenu isOpen={isProfileMenuOpen} close={toggleProfileMenu} />
        <Stack gap='xl' align='center' w='100%'>
          <Center onClick={closeMobileMenu} w='100%'>
            <BottomNavigationMenu isSmall={isMinimal} />
          </Center>
          <UserSection
            toggleProfileMenu={toggleProfileMenu}
            isSmall={isMinimal}
            userName={accountName!}
            userAvatarUrl={viewer?.avatarUrl || DEFAULT_AVATAR_IMAGE}
            userWalletAddress={address as string}
          />
        </Stack>
      </>
    ) : (
      <Center>
        <ConnectButton isMinimal={isMinimal} isXL={false} />
      </Center>
    )
  }

  return bg ? (
    <>
      <BackgroundImage src={bg} pos='fixed' h='100%' />
      <Box h='100%' mih='100vh' w='100%' className='overflow-y-scroll'>
        <SummonUiDashboardLayout
          renderNavigationMenu={renderNavigationMenu}
          renderBottomMenu={renderBottomMenu}
          appVersion={APP_VERSION}
          render={({ offset }) =>
            isLoading ? (
              <Box h='100vh'>
                <Spinner />
              </Box>
            ) : (
              <Outlet context={{ offset, isAuthenticated }} />
            )
          }
        />
      </Box>
    </>
  ) : null
}

export default withAuth(DashboardLayout)
