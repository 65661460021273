import { Title, Text } from 'summon-ui/mantine'

type OnboardingHeaderProps = {
  title: string
  subTitle: string
}

const OnboardingHeader = ({ title, subTitle }: OnboardingHeaderProps) => {
  return (
    <>
      <Title fz={{ xs: 'h3', md: 'h1' }}>{title}</Title>
      <Text fz={{ xs: 'sm', md: 'md' }} mb='sm'>
        {subTitle}
      </Text>
    </>
  )
}

export default OnboardingHeader
