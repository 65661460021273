import { useLocation, useNavigate } from 'react-router-dom'
import { ContentHeader, ContentLayout } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { SettingsViews } from '@features/settings'
import { SETTINGS_TABS } from '@features/settings/settings.config'
import { withProtectedRoute } from '@hocs'

const SettingsPage = () => {
  const t = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const list = SETTINGS_TABS.map((tab) => ({ name: tab.name, to: tab.to, action: () => navigate(tab.to) }))

  return (
    <ContentLayout renderContentHeader={() => <ContentHeader name={t('Settings')} tabs={{ list, value: pathname }} />}>
      <SettingsViews />
    </ContentLayout>
  )
}

export default withProtectedRoute(SettingsPage)
