import { forwardRef, useEffect, useRef, useState } from 'react'
import { Stage, Layer, Image } from 'react-konva'
import useImage from 'use-image'
import { InventoryItem } from '@generated/generates'

type Props = {
  items: InventoryItem[]
}

const Avatar2D = forwardRef(({ items }: Props, ref) => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const stageRef = useRef<any>(null)
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const stageParent = useRef<any>(null)
  const [dimension, setDimension] = useState(600)

  useEffect(() => {
    setDimension(Math.min(stageParent.current?.clientHeight, stageParent.current?.clientWidth))
  }, [stageParent])

  return (
    <div className='flex flex-col gap-4 justify-center items-center w-screen md:w-full mt-10' ref={stageParent}>
      <Stage ref={ref || stageRef} width={dimension} height={dimension}>
        <Layer>
          {items
            .filter((item) => item.isEquipped)
            // this is for testing
            .map((item) => (
              <LoadImage key={item.id} url={item.glbUrl} dimension={dimension - 15} />
            ))}
        </Layer>
      </Stage>
    </div>
  )
})

const LoadImage = ({ url, dimension }: { url: string; dimension: number }) => {
  const [image] = useImage(url, 'anonymous', 'origin')
  return <Image image={image} width={dimension} height={dimension} />
}

Avatar2D.displayName = 'Avatar2D'
export default Avatar2D
