import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios, { AxiosError } from 'axios'
import { showNotification, Spinner } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Center, Stack, Title } from 'summon-ui/mantine'
import { TENANT_API } from '@config'
import { getJWT } from '@utils/auth'

interface ErrorResponseData {
  errorCode?: string
}

const ALREADY_CONNECTED = 'alreadyConnected'
const discordLoginUrl = `${TENANT_API.rest}/integration/discord/login`

const RedirectPage = () => {
  const t = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.hash)
  const token = params.get('access_token')
  const redirectUrl = params.get('state')
  const error = params.get('error_description')
  const jwt = getJWT()
  const loginUrl = `${discordLoginUrl}?discordAccessToken=${token}&accessToken=${jwt}&noRedirect=true`

  useEffect(() => {
    const handleRedirect = async () => {
      if (error) {
        showNotification({
          variant: 'danger',
          message: `Error: ${error}`
        })
        navigate(redirectUrl as string)
        return
      }

      if (token) {
        try {
          await axios.get(loginUrl)
          navigate(redirectUrl || '/', { replace: true })
        } catch (error) {
          const axiosError = error as AxiosError<ErrorResponseData>

          const isAlreadyConnected = axiosError?.response?.data?.errorCode === ALREADY_CONNECTED
          if (isAlreadyConnected) {
            navigate(`${redirectUrl}?errorCode=${ALREADY_CONNECTED}` || '/', { replace: true })
          }
        }
      }
    }

    handleRedirect()
  }, [])

  return (
    <div className='h-screen w-screen'>
      <Center w='100%' h='100%'>
        <Stack gap='sm'>
          <Title order={1}>{t('You will be redirected automatically')}</Title>
          <Spinner size={40} />
        </Stack>
      </Center>
    </div>
  )
}

export default RedirectPage
