import { useOutletContext } from 'react-router-dom'
import ConnectedHomePage from '@pages/home/ConnectedHomePage'
import GuestHomePage from '@pages/home/GuestHomePage'

const HomePage = () => {
  const { isAuthenticated } = useOutletContext<{ isAuthenticated: boolean }>()
  return isAuthenticated ? <ConnectedHomePage /> : <GuestHomePage />
}

export default HomePage
