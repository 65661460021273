import { createBrowserRouter } from 'react-router-dom'
import { ErrorBoundary, ScrollToTop } from '@components'
import { TENANT_CONFIG } from '@config'
import DashboardLayout from '@layouts/DashboardLayout/DashboardLayout'
import OnboardingLayout from '@layouts/OnboardingLayout'
import CreditsPage from '@pages/CreditsPage'
import HistoryPage from '@pages/HistoryPage'
import LeaderboardPage from '@pages/LeaderboardPage'
import MaintenancePage from '@pages/MaintenancePage'
import MarketplacePage from '@pages/MarketplacePage'
import QuestsPage from '@pages/QuestsPage'
import RedirectPage from '@pages/RedirectPage'
import SettingsPage from '@pages/SettingsPage'
import OnboardingPage from '@pages/auth/OnboardingPage'
import HomePage from '@pages/home'
import LootdropsPage from './pages/LootdropsPage'

const router = createBrowserRouter([
  {
    element: <RedirectPage />,
    path: '/callback'
  },
  {
    element: <MaintenancePage />,
    path: '/lockdown'
  },
  {
    element: <OnboardingLayout />,
    children: [
      {
        path: '/onboarding/*',
        element: <OnboardingPage />,
        errorElement: <ErrorBoundary />
      }
    ]
  },
  {
    element: (
      <ScrollToTop>
        <DashboardLayout />
      </ScrollToTop>
    ),
    children: [
      {
        path: '/quests/*',
        element: <QuestsPage />
      },
      ...(TENANT_CONFIG.features.lootdrops
        ? [
            {
              path: '/lootdrop/*',
              element: <LootdropsPage />
            }
          ]
        : []),
      // {
      //   path: '/proposals/*',
      //   element: <ProposalsPage />
      // },

      {
        path: '/history/*',
        element: <HistoryPage />
      },

      {
        path: '/credits/*',
        element: <CreditsPage />
      },
      {
        path: '/settings/*',
        element: <SettingsPage />
      },
      {
        path: '/leaderboard/*',
        element: <LeaderboardPage />
      },
      {
        path: '/marketplace/*',
        element: <MarketplacePage />
      },

      {
        path: '/*',
        element: <HomePage />
      }
    ]
  }
])

export default router
