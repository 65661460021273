import { useGqlQuery } from '@api/gql/gqlServices'
import { Category, GetCategoriesDocument, GetCategoriesQuery } from '@generated/generates'
import { useViewer } from '@hooks'

const useQuestsCategories = () => {
  const { isAuthenticated } = useViewer()
  const { data: categoriesData, isLoading: isCategoriesLoading } = useGqlQuery<GetCategoriesQuery>(
    GetCategoriesDocument,
    {
      isActive: true,
      userCompletion: isAuthenticated,
      showLootDropCategories: false
    }
  )

  const featuredCategory = categoriesData?.categories.find((category) => category.isFeatured)
  const questsCategories = categoriesData?.categories.filter((category) => !category.isFeatured)

  return {
    questsCategories: (questsCategories as Category[]) || [],
    isCategoriesLoading,
    featuredCategory
  }
}

export default useQuestsCategories
