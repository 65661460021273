import { useState } from 'react'
import { graphqlClient } from '@api/config'
import { TENANT_CONFIG } from '@config'
import { AddOnChainTransactionHistoryMutation, useAddOnChainTransactionHistoryMutation } from '@generated/generates'

interface ReturnType {
  addOnchainHistoryLog: ({
    txAddress,
    contractAddress,
    description,
    explorerLink
  }: {
    txAddress: string
    contractAddress: string
    description: string
    explorerLink?: string
  }) => Promise<AddOnChainTransactionHistoryMutation>
  isLogAddedSuccessfully: boolean
}

const { network } = TENANT_CONFIG
const explorerUrl = network.blockExplorers?.default.url
const tenantChainId = network.id

const useAddOnchainHistoryLog = (): ReturnType => {
  const [isLogAdded, setIsLogAdded] = useState(false)
  const { mutateAsync: addOnchainHistory } = useAddOnChainTransactionHistoryMutation(graphqlClient(), {
    onSuccess() {
      setIsLogAdded(true)
    },
    onError() {
      console.error('Error loging tx to History')
    }
  })

  const addOnchainHistoryLog = ({
    txAddress,
    contractAddress,
    description,
    explorerLink
  }: {
    txAddress: string
    contractAddress: string
    description: string
    explorerLink?: string
  }) => {
    return addOnchainHistory({
      txAddress,
      contractAddress,
      chainId: tenantChainId,
      description,
      txLink: explorerLink || `${explorerUrl}/tx/${txAddress}`
    })
  }

  return { addOnchainHistoryLog, isLogAddedSuccessfully: isLogAdded }
}

export default useAddOnchainHistoryLog
