import { useState } from 'react'
import { CopyToClipboard, Icon } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Avatar, Text, Title, useMantineColorScheme, Flex, Stack, Box } from 'summon-ui/mantine'
import { useLogout } from '@hooks'
import { sliceAddress } from '@utils/strings'

const UserSection = ({
  toggleProfileMenu,
  userName,
  userAvatarUrl,
  userWalletAddress,
  isSmall
}: {
  toggleProfileMenu: () => void
  userName: string
  userAvatarUrl: string
  userWalletAddress: string
  isSmall: boolean
}) => {
  const t = useTranslation()
  const { logout } = useLogout()
  const { colorScheme } = useMantineColorScheme()
  const [isUserInfoHovered] = useState(false)
  const [isLogoutHovered, setIsLogoutHovered] = useState(false)

  return (
    <Flex py='xs' px={isSmall ? 0 : 8} align='center' h={48} justify={isSmall ? 'center' : 'space-between'} w='100%'>
      <Flex gap='xs' align='center'>
        <Avatar
          data-testid='user-section-avatar-button'
          className='cursor-pointer h-full hover:scale-110 transition-all duration-200 hover:opacity-80x'
          src={userAvatarUrl}
          alt={t('User Avatar')}
          onClick={toggleProfileMenu}
        />
        {!isSmall && (
          <CopyToClipboard
            value={userWalletAddress}
            render={({ copy, copied }) => (
              <Box className='hover:cursor-pointer' bg='transparent' onClick={copy}>
                <Stack gap={0} justify='center' h={48}>
                  <Title maw={125}>
                    <Text size='xs' truncate>
                      {userName}
                    </Text>
                  </Title>
                  <Flex onClick={copy} gap={4} align='center'>
                    <Text
                      data-testid='user-section-wallet-button'
                      size='sm'
                      c={isUserInfoHovered ? 'brand.5' : ''}
                      className='transition-all duration-200'
                    >
                      {sliceAddress(userWalletAddress)}
                    </Text>
                    <Icon name={copied ? 'Check' : 'Copy01'} size='sm' c={copied ? 'green' : 'gray'} />
                  </Flex>
                </Stack>
              </Box>
            )}
          />
        )}
      </Flex>
      {!isSmall && (
        <Icon
          data-testid='user-section-logout-button'
          size='lg'
          name='LogOut01'
          onClick={logout}
          c={isLogoutHovered ? 'red.6' : colorScheme === 'light' ? 'gray.6' : 'dark.1'}
          className='duration-200'
          onMouseEnter={() => setIsLogoutHovered(true)}
          onMouseLeave={() => setIsLogoutHovered(false)}
        />
      )}
    </Flex>
  )
}

export default UserSection
