import { Badge, Image, Stack } from 'summon-ui/mantine'

interface Props {
  image: string
  count: number
  areConditionsMet: boolean
  name: string
}

const BlueprintMaterialSlot = ({ image, count, areConditionsMet, name }: Props) => {
  return (
    <Stack h='100%' align='center' gap={0}>
      <Image src={image} w={150} alt='blueprint' />
      <Badge mb='xs' variant='light' color={areConditionsMet ? 'green' : 'red'}>
        {`${count} ${name}`}
      </Badge>
    </Stack>
  )
}

export default BlueprintMaterialSlot
