import { MarketplaceType } from '@config/types'
import CONTRACTS_CONFIG from './contracts.config'

const BASIS_POINT = 200

const MARKETPLACE_CONFIG: MarketplaceType = {
  apiToken: '39734bfe-e03f-5de1-82f3-4b758c1cc534',
  contract: CONTRACTS_CONFIG.mainnet.marketplace,
  chainId: 324,
  chainName: 'zkSync',
  baseApiUrl: 'https://api-zksync.reservoir.tools',
  marketplaceFees: [`${CONTRACTS_CONFIG.mainnet.marketplaceFees}:${BASIS_POINT}`]
}

MARKETPLACE_CONFIG.enabled = true
export default MARKETPLACE_CONFIG
