import { useGqlQuery } from '@api/gql/gqlServices'
import { GetCategorySlugDocument, GetCategorySlugQuery } from '@generated/generates'

interface Props {
  id: string
}

const useGetCategorySlug = ({ id }: Props) => {
  const { data: categoryData, isLoading: isCategoryLoading } = useGqlQuery<GetCategorySlugQuery>(
    GetCategorySlugDocument,
    {
      categoryId: id
    }
  )

  return {
    urlSlug: categoryData?.category?.urlSlug,
    isCategoryLoading
  }
}

export default useGetCategorySlug
