import { PropsWithChildren } from 'react'
import { Title, Text, Box, Stack, Flex } from 'summon-ui/mantine'

type Props = {
  name: string
  description: string
} & PropsWithChildren

export const SettingItem = ({ name, description, children }: Props) => (
  <Flex direction={{ base: 'column', md: 'row' }} gap='lg' py='md'>
    <Box w={300}>
      <Title order={5}>{name}</Title>
      <Text size='sm'>{description}</Text>
    </Box>

    <Stack gap='md' pt={{ base: 'md', md: '0' }} w='100%'>
      {children}
    </Stack>
  </Flex>
)
