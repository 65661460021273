import { lazy, Suspense } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ContentHeader, ContentLayout, Spinner } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { TENANT_CONFIG } from '@config'
import { Cart } from '@features/marketplace/components/Cart'
import { useFilter } from '@features/marketplace/contexts/FilterContext'
import { withCart } from '@features/marketplace/hocs/withCart'
import { withFilter } from '@features/marketplace/hocs/withFilter'
import { useCart } from '@features/marketplace/hooks/useCart'
import { MARKETPLACE_TABS } from '@features/marketplace/marketplace.config'

const MarketplaceViews = lazy(() => import('@features/marketplace'))

const MarketplacePage = () => {
  const t = useTranslation()
  const { setIsOpen: setIsFilterOpen } = useFilter()
  const cart = useCart()
  const location = useLocation()
  const navigate = useNavigate()
  const list = MARKETPLACE_TABS.map((tab) => ({ name: tab.name, to: tab.to, action: () => navigate(tab.to) }))

  const actions = TENANT_CONFIG.api.shopify
    ? [
        ...(location.pathname.includes('collections')
          ? [
              {
                name: 'Filters',
                iconName: 'FilterLines',
                action: () => setIsFilterOpen(true)
              }
            ]
          : []),
        {
          name: `${cart.totalItemsInCart} Items`,
          iconName: 'ShoppingBag01',
          action: () => cart.setIsCartOpen(true)
        }
      ]
    : []

  return (
    <ContentLayout
      renderContentHeader={() => (
        <ContentHeader name={t('Marketplace')} tabs={{ list, value: location.pathname }}>
          {actions?.map((action) => <ContentHeader.Button {...action} key={action.name} />)}
        </ContentHeader>
      )}
    >
      <Suspense fallback={<Spinner />}>
        <MarketplaceViews />
      </Suspense>
      <Cart isOpen={cart.isCartOpen} onClose={() => cart.setIsCartOpen(false)} />
    </ContentLayout>
  )
}

export default withCart(withFilter(MarketplacePage))
