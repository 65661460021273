import { sliceAddress } from '@utils/strings'
import { LeaderboardTableElement, LeaderboardUser } from '../types'

const buildUpLeaderboardTableData = (
  leaderboard: Array<LeaderboardUser>,
  currentUserInfo: LeaderboardUser
): Array<LeaderboardTableElement> => {
  const updatedLeaderboard = [
    ...(currentUserInfo.walletAddress
      ? [
          {
            rank: currentUserInfo.position,
            member: {
              avatarUrl: currentUserInfo.avatarUrl,
              wallet:
                currentUserInfo.username ||
                currentUserInfo.firstName ||
                sliceAddress(currentUserInfo.walletAddress as string, 5)
            },
            XP: currentUserInfo.xp
          }
        ]
      : []),
    ...leaderboard.map((user) => ({
      rank: user.position,
      member: {
        avatarUrl: user.avatarUrl,
        wallet: user.username || user.firstName || sliceAddress(user.walletAddress as string, 5)
      },
      XP: user.xp
    }))
  ]

  return updatedLeaderboard
}

export { buildUpLeaderboardTableData }
