import { useEffect, useRef } from 'react'
import { CameraControls } from '@react-three/drei'

type CustomCameraControlsProps = {
  rotate: boolean
}
const CustomCameraControls = ({ rotate }: CustomCameraControlsProps) => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const cameraControlsRef = useRef<any>()

  useEffect(() => {
    cameraControlsRef.current?.setPosition(-4, 0, 4)
    cameraControlsRef.current?.lookInDirectionOf(10, 0, 4, true)
    cameraControlsRef.current?.rotate(8, 0, true)
  }, [rotate])

  return <CameraControls enabled={true} ref={cameraControlsRef} maxDistance={9} minDistance={6} />
}

export default CustomCameraControls
