import { showNotification } from 'summon-ui'
import { formatUnits } from 'viem'
import { useBalance } from 'wagmi'
import { graphqlClient } from '@api/config'
import { TENANT_CONFIG, TENANT_CONTRACTS } from '@config'
import { useMintAvatarMutation } from '@generated/generates'
import { isStaging } from '@utils/isStaging'

const MIN_BALANCE_TO_SPONSOR = isStaging ? 0.025 : 0.0001

const useSponsoredAvatarMint = () => {
  const { data } = useBalance({
    address: isStaging ? TENANT_CONTRACTS.testnet.achievoWallet : TENANT_CONTRACTS.mainnet.achievoWallet,
    chainId: TENANT_CONFIG.network.id
  })

  const hasBalance = data ? Number(formatUnits(data.value, data.decimals)) > MIN_BALANCE_TO_SPONSOR : null

  const { mutateAsync: mintAvatarMutation, isPending: isMinting } = useMintAvatarMutation(graphqlClient(), {
    onSuccess: () => {
      showNotification({
        variant: 'success',
        message: 'Avatar minted successfully!'
      })
    },
    onError: () => {
      showNotification({
        variant: 'danger',
        message: 'Something went wrong, try again later!'
      })
    }
  })

  return {
    hasBalanceToSponsor: hasBalance,
    mintAvatarMutation,
    isMinting
  }
}

export default useSponsoredAvatarMint
