import { RefObject, forwardRef } from 'react'
import { Text, Stack, Box } from 'summon-ui/mantine'
import { TENANT_CONFIG } from '@config'
import { Tenant } from '@config/enum'
import AvatarPreview from '@modules/avatar'

const TITLE = 'Share your Avatar'
const DESCRIPTION = 'Display your cool new avatar to your friends over social media.'
const NOTE = 'NOTE: You need to download your avatar first so you can attach it.'

interface ShareAvatarProps {
  ref?: RefObject<HTMLCanvasElement>
}

const isGame7Avatar = TENANT_CONFIG.name === Tenant.G7

const ShareAvatar = forwardRef((props: ShareAvatarProps, ref) => {
  return (
    <Stack gap='xs'>
      <Box className='p-2 rounded-md h-64 overflow-hidden'>
        <AvatarPreview
          previewMode
          ref={ref} // This ref should match the type specified in ShareAvatarProps
          centerProps={{
            position: [0, isGame7Avatar ? -1 : 0, 0],
            zoom: 11
          }}
        />
      </Box>
      <Stack gap='xs' className='p-4'>
        <Text ta='center' fw='bold' size='xl'>
          {TITLE}
        </Text>
        <Text ta='center'>
          {DESCRIPTION}{' '}
          <Text span ta='center' fw='bold'>
            {NOTE}
          </Text>
        </Text>
      </Stack>
    </Stack>
  )
})

ShareAvatar.displayName = 'ShareAvatar'
export default ShareAvatar
