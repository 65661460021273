import { useParams } from 'react-router-dom'
import { useGqlQuery } from '@api/gql/gqlServices'
import { GetLootDropQuery, GetLootDropDocument, LootDrop, Maybe } from '@generated/generates'

interface UseLootdropDetailsResult {
  isLoading: boolean
  lootdrop: Maybe<LootDrop>
}

const useLootdrop = (): UseLootdropDetailsResult => {
  const params = useParams()
  const { data, isLoading } = useGqlQuery<GetLootDropQuery>(
    GetLootDropDocument,
    {
      urlSlug: params.lootdropSlug
    },
    { enabled: !!params.lootdropSlug }
  )

  return {
    isLoading,
    lootdrop: data?.getLootDrop ?? null
  }
}

export default useLootdrop
