/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import axios from 'axios'
import { TENANT_API } from '@config'
import { InventoryItem } from '@generated/generates'
import { BaseSkinContractData, InitialItemData } from '@hooks/inventory/useInventoryItems'
import { restClient } from './config'

export type EnrichedMetadata = {
  id: string
  name: string
  description: string
  image: string
  animation_url: string
  attributes: {
    trait_type: string
    value: string
  }
}

// GET
export const search = async ({ queryKey }: any) => {
  const [_key, { keyword }] = queryKey
  const { data: response } = await restClient.get(`/scan/search/${keyword}`)
  return response.data
}

export const getSignedUpload = async () => {
  const { data: response } = await restClient.get(`/utility/image/cf-image-sign`)
  return response.data.result.uploadURL
}

//POST
export const postAuthUser = async (signedMessage: string) => {
  const body = {
    signed_msg: signedMessage
  }
  const { data: response } = await restClient.post('/auth/user', body)
  return response.data
}

export const postCharmverseAuth = async () => {
  const body = {
    tenantKey: TENANT_API.tenantKey
  }
  const { data: response } = await restClient.post('/integration/charmverse/login', body)
  return response
}

export const getBaseSkinsMetadataList = async ({ queryKey }: any): Promise<EnrichedMetadata[]> => {
  const [_key, { baseSkinsContractData }] = queryKey

  if (!baseSkinsContractData) {
    console.error('Error: baseSkinsContractData is not defined.')
    return []
  }

  const promises = baseSkinsContractData?.map(async (baseSkin: BaseSkinContractData) => {
    try {
      const response = await axios.get(baseSkin.tokenUri)
      const metadata = response.data
      // Add "id" field to metadata to use it when mint
      const enrichedMetadata = { ...metadata, id: baseSkin.baseSkinId }

      return enrichedMetadata
    } catch (error: any) {
      console.error(`Error fetching metadata for baseSkinId ${baseSkin.baseSkinId}: `, error?.message)
      return null
    }
  })

  const promisesRes = await Promise.allSettled(promises)
  const filterSuccessfulRes = promisesRes?.filter(
    (res) => res.status === 'fulfilled'
  ) as PromiseFulfilledResult<EnrichedMetadata>[]

  const res = filterSuccessfulRes.map((res) => res.value)

  return res
}

export const getItemsWithMetadata = async ({ queryKey }: any): Promise<InventoryItem[]> => {
  const [_key, { itemsData }] = queryKey

  if (!itemsData) {
    console.error('Error: itemsData is not defined.')
    return []
  }

  const promises = itemsData?.map(async (initialItemData: InitialItemData) => {
    try {
      const response = await axios.get(initialItemData.tokenUri)
      const metadata = response.data

      const itemEnrichedWithMetadata = {
        id: initialItemData?.id,
        chain: initialItemData?.chain,
        slotType: initialItemData?.slotType,
        isEquipped: initialItemData.isEquipped,
        count: initialItemData?.amount,
        soulbound: initialItemData?.soulbound,
        uri: initialItemData.tokenUri,
        // We ask only for Slot size x2 version
        imageUrl: `${metadata?.image}?img-width=220`,
        glbUrl: metadata?.animation_url
      }

      return itemEnrichedWithMetadata
    } catch (error: any) {
      console.error(`Error fetching metadata for item ${initialItemData.tokenId}: `, error?.message)
      return null
    }
  })

  const promisesRes = await Promise.allSettled(promises)
  const filterSuccessfulRes = promisesRes?.filter(
    (res) => res.status === 'fulfilled'
  ) as PromiseFulfilledResult<InventoryItem>[]

  const res = filterSuccessfulRes.map((res) => res.value)

  return res
}

export const getTokenMetadata = async ({ queryKey }: any) => {
  const [_key, { tokenURI }] = queryKey
  try {
    const response = await axios.get(tokenURI)
    const metadata = response.data

    return metadata
  } catch (error: any) {
    console.error(`Error fetching metadata for item ${tokenURI}: `, error?.message)
    return null
  }
}

export const addToQueue = (address: `0x${string}`) => {
  return axios.post('https://queue.summon.xyz/queue', address, {
    headers: {
      'Content-type': 'text/plain',
      Key: '6TbxVxhLhualgeAMrmISjwvdUiceQzsaEXnB9d5C_test',
      Authorization:
        'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IiIsImlhdCI6MTUxNjIzOTAyMn0.0axYYGxdpcYlDFBRr5kmrB9AJn6aSRHP5N7S89s3aSc'
    }
  })
}
