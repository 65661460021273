import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import { useUpdateCurrentUserSettingsMutation } from '@generated/generates'
import { useViewer } from '@hooks'

interface Props {
  callback?: () => void
}

const useCreditsOnboarding = ({ callback }: Props) => {
  const queryClient = useQueryClient()
  const { viewer } = useViewer()
  const [slide, setSlide] = useState(0)
  const isFirstSlide = slide === 0
  const [videoLoaded, setVideoLoaded] = useState(false)
  const [checked, setChecked] = useState(false)
  const refreshUserQuery = () => queryClient.invalidateQueries({ queryKey: ['GetUser'] })
  const [isCompletingOnboarding, setIsCompletingOnboarding] = useState(false)

  const { mutateAsync: updateUserSettingsMutation } = useUpdateCurrentUserSettingsMutation(graphqlClient(), {
    onSuccess() {
      refreshUserQuery()
      callback && callback()
    }
  })

  const completeCreditsOnboarding = async () => {
    setIsCompletingOnboarding(true)
    try {
      await updateUserSettingsMutation({ isCreditsOnboardingCompleted: true })
    } catch (error) {
      console.error('Error completing Credits onboarding: ', error)
    } finally {
      setIsCompletingOnboarding(false)
      refreshUserQuery()
    }
  }

  const handleClickActionButton = () => {
    return isFirstSlide ? setSlide(1) : completeCreditsOnboarding()
  }

  return {
    isCreditsOnboardingCompleted: viewer?.isCreditsOnboardingCompleted,
    handleClickActionButton,
    slide,
    setSlide,
    checked,
    setChecked,
    videoLoaded,
    setVideoLoaded,
    isCompletingOnboarding
  }
}

export default useCreditsOnboarding
