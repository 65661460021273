import { memo, useEffect, useState } from 'react'
import { EnhancedPaper, Spinner, showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Button, Center, Divider, Group, Modal, Stack, Title, Text } from 'summon-ui/mantine'
import { useBlockNumber, useReadContract } from 'wagmi'
import { TENANT_ONBOARDING, TENANT_CONTRACTS, TENANT_CONFIG } from '@config'
import { AVATAR_BOUND_ABI } from '@constants/Abi/avatarBoundAbi'
import { useLogout, useViewer } from '@hooks'
import { isStaging } from '@utils/isStaging'
import AvatarWithInventory from '../components/AvatarWithInventory'
import OnboardingHeader from '../components/OnboardingHeader'
import ReferralModal from '../components/ReferralModal'
import withWaitingRoom from '../components/withWaitingRoom'
import useOnboardingUtils from '../hooks/useOnboardingUtils'
import useSponsoredAvatarMint from '../hooks/useSponsoredAvatarMint'
import { useOnboardingStorage } from '../onboarding.store'

const CONFIRM_MODAL_DESCRIPTION =
  'By minting you will receive a soulbound avatar, as well as several equippable NFT items.'

const Mint = () => {
  const t = useTranslation()
  const { viewer } = useViewer()
  const { logout } = useLogout()
  const { setStep, selectedOnboardingSkin, step } = useOnboardingStorage()
  const { saveAvatar } = useOnboardingUtils()
  const [isMintModalOpen, setIsMintModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { mintAvatarMutation } = useSponsoredAvatarMint()
  const handleFinishStep = () => {
    setIsMintModalOpen(false)
    setIsLoading(false)
    setStep(1)
  }

  const baseSkinId = Number(selectedOnboardingSkin?.id)

  const { data: tokenIdData, refetch } = useReadContract({
    address: isStaging ? TENANT_CONTRACTS.testnet.avatar : TENANT_CONTRACTS.mainnet.avatar,
    abi: AVATAR_BOUND_ABI,
    chainId: TENANT_CONFIG.network.id,
    functionName: 'tokenOfOwnerByIndex',
    args: [viewer?.walletAddress, 0],
    query: { enabled: !!viewer?.walletAddress }
  })
  const { data: blockNumber } = useBlockNumber({ watch: !tokenIdData, chainId: TENANT_CONFIG.network.id })

  useEffect(() => {
    if (!tokenIdData) {
      refetch()
    } else {
      const saveAndMarkAsClaimed = async () => {
        await saveAvatar({ tokenId: Number(tokenIdData) as number, skinId: selectedOnboardingSkin?.glbUrl as string })

        handleFinishStep()
      }
      saveAndMarkAsClaimed()
    }
  }, [blockNumber, tokenIdData])

  const mint = async () => {
    try {
      setIsLoading(true)
      await mintAvatarMutation({ baseSkinId })
    } catch (error) {
      setIsLoading(false)
      showNotification({
        variant: 'danger',
        message: 'Could not mint the Avatar'
      })
    }
  }

  const handleMint = () => {
    setIsMintModalOpen(true)
  }

  return (
    <div className='h-full w-full'>
      {isLoading ? (
        <Center w='100%' h='100%'>
          <Spinner isFull />
        </Center>
      ) : (
        <>
          <Text hiddenFrom='md' fz='xs'>
            STEP {step + 1}/2
          </Text>
          <OnboardingHeader title={TENANT_ONBOARDING.avatar.title} subTitle={TENANT_ONBOARDING.avatar.subtitle} />
          <AvatarWithInventory isDNA>
            <Group justify='space-between'>
              <Button miw={120} onClick={logout} variant='outline' disabled={isLoading} loading={isLoading}>
                {t('Cancel')}
              </Button>
              <Button miw={120} onClick={handleMint} disabled={isLoading} loading={isLoading}>
                {t('Mint')}
              </Button>
            </Group>
          </AvatarWithInventory>

          <EnhancedPaper withBg w='100%' className='md:absolute fixed bottom-0 left-0' visibleFrom='md'>
            <Divider mb='md' />
            <Group justify='end' px='sm' mb='md'>
              <Button
                data-testid='create-avatar-mint-button'
                miw={100}
                onClick={handleMint}
                disabled={isLoading}
                loading={isLoading}
              >
                {t('Mint')}
              </Button>
            </Group>
          </EnhancedPaper>
          <ReferralModal />
          <Modal opened={isMintModalOpen} onClose={() => setIsMintModalOpen(false)} maw={400} radius='md'>
            <ConfirmModal
              disabled={false}
              isLoading={isLoading}
              action={mint}
              close={() => setIsMintModalOpen(false)}
              isSponsored
            />
          </Modal>
        </>
      )}
    </div>
  )
}

const ConfirmModal = memo(
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  withWaitingRoom(({ close, isLoading, disabled, nextStep }: any) => {
    const t = useTranslation()

    return (
      <Stack gap='xs' p='sm' mt='lg'>
        <Title order={3}>{t("You're about to Mint!")}</Title>
        <Text>{t(CONFIRM_MODAL_DESCRIPTION)}</Text>
        <Group justify='space-between' mt='md'>
          <Button variant='outline' onClick={close}>
            {t('Cancel')}
          </Button>
          <Button
            data-testid='create-avatar-modal-continue-button'
            onClick={nextStep}
            disabled={disabled || isLoading}
            loading={isLoading}
          >
            {t('Continue')}
          </Button>
        </Group>
      </Stack>
    )
  })
)

export default Mint
