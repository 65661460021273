import { useQuery } from '@tanstack/react-query'
import { shopifyClient } from '@api/modules/shopify'
import { Cart } from '@api/modules/shopify/types'

async function getCart(id: string) {
  const data = await shopifyClient({
    query: `
      query GetCart($id: ID!) {
        cart(id: $id) {
          id
          checkoutUrl
          estimatedCost {
            totalAmount {
              amount
            }
          }
          lines(first: 100) {
            edges {
              node {
                id
                quantity
                estimatedCost {
                  subtotalAmount {
                    amount
                    currencyCode
                  }
                  totalAmount {
                    amount
                    currencyCode
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      id
                      altText
                      url
                    }
                    product {
                      title
                      featuredImage {
                        originalSrc
                        altText
                      }
                    }
                    priceV2 {
                      amount
                      currencyCode
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: { id }
  })

  return data.cart as Cart
}

export const useCartQuery = ({ id, refetchInterval }: { id: string; refetchInterval?: number }) => {
  return useQuery({ queryKey: ['key', id], queryFn: () => getCart(id), enabled: !!id, refetchInterval })
}
