import { useState } from 'react'
import { showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { graphqlClient } from '@api/config'
import { useUpdateCurrentUserSettingsMutation } from '@generated/generates'

const useUpdateTelegramId = () => {
  const t = useTranslation()
  const [isUpdatingTelegramId, setIsUpdatingTelegramId] = useState(false)

  const { mutateAsync: updateUserSettingsMutation } = useUpdateCurrentUserSettingsMutation(graphqlClient(), {})

  const updateTelegramId = async ({ id, onSuccess }: { id: string; onSuccess?: () => void }) => {
    setIsUpdatingTelegramId(true)
    try {
      await updateUserSettingsMutation({ telegramId: id })
      showNotification({
        variant: 'success',
        message: t('Telegram ID updated successfully')
      })
      onSuccess && onSuccess()
    } catch (error) {
      console.error(error)

      showNotification({
        variant: 'danger',
        message: t('There was an error updating the Telegram ID')
      })
    } finally {
      setIsUpdatingTelegramId(false)
    }
  }

  return {
    updateTelegramId,
    isUpdatingTelegramId
  }
}

export default useUpdateTelegramId
