import { useState, useEffect } from 'react'
import { useDebouncedState } from 'summon-ui/mantine'
import { useAccount } from 'wagmi'
import { useGqlQuery } from '@api/gql/gqlServices'
import {
  GetLeaderboardDocument,
  GetLeaderboardQuery,
  GetUserLeaderboardDocument,
  GetUserLeaderboardQuery
} from '@generated/generates'
import { CampaignKey, LeaderboardUser } from '../types'
import { buildUpLeaderboardTableData } from './helpers'

const useLeaderboard = (pageSize: number, campaignKey?: CampaignKey) => {
  const [currentPage, setCurrentPage] = useDebouncedState(1, 200)
  const { isConnected } = useAccount()
  const [totalUsersCount, setTotalUsersCount] = useState<number>(0)
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0)
  const { data, isLoading, status, refetch } = useGqlQuery<GetLeaderboardQuery>(GetLeaderboardDocument, {
    limit: pageSize,
    offset: (currentPage - 1) * pageSize,
    campaignKey
  })

  const { data: userLeaderboardData } = useGqlQuery<GetUserLeaderboardQuery>(
    GetUserLeaderboardDocument,
    {
      campaignKey
    },
    { enabled: isConnected } // Disable caching
  )

  useEffect(() => {
    if (data && data.topUsersCount !== undefined) {
      setTotalUsersCount(data.topUsersCount)
      setTotalPagesCount(Math.ceil(data.topUsersCount / pageSize))
    }
  }, [data, pageSize])

  const handleRetrievePage = (page: number) => {
    setCurrentPage(page)
  }

  return {
    isLoading,
    status,
    refetch,
    leaderboard:
      status === 'success'
        ? buildUpLeaderboardTableData(
            data?.topUsers as LeaderboardUser[],
            {
              ...userLeaderboardData?.viewer,
              position: userLeaderboardData?.viewerTopUser?.position
            } as LeaderboardUser
          )
        : [],
    currentPage,
    navigateToPage: handleRetrievePage,
    totalPagesCount,
    totalUsersCount,
    isUserInLeaderboard: !!userLeaderboardData
  }
}

export default useLeaderboard
