import { EnhancedPaper, Icon } from 'summon-ui'
import { ActionIcon, Box, Flex, Stack, Stepper } from 'summon-ui/mantine'
import { ResponsiveLogo } from '@components'
import { useLogout } from '@hooks'
import { useOnboardingStorage } from '../onboarding.store'

type OnboardingSidebarType = {
  steps: { id: number; name: string; description: string }[]
}
export default function OnboardingSidebar({ steps }: OnboardingSidebarType) {
  const activeStep = useOnboardingStorage((state) => state.step)
  const { logout } = useLogout()
  return (
    <EnhancedPaper miw={300} py={40} px={30} withBg pos='sticky' top='0' className='min-h-screen'>
      <Stack gap='50'>
        <Flex justify='space-between' align='center'>
          <ResponsiveLogo isClickable={false} />
          <ActionIcon size='32' onClick={logout}>
            <Icon name='LogOut01' />
          </ActionIcon>
        </Flex>
        <nav aria-label='progress' className='h-full'>
          <Stepper active={activeStep}>
            {steps.map(({ id, name, description }) => (
              <Stepper.Step
                key={id}
                label={name}
                description={description}
                icon={<Box className='rounded-full' h='sm' w='sm' bg={id === activeStep ? 'brand.6' : 'dark.7'} />}
              />
            ))}
          </Stepper>
        </nav>
      </Stack>
    </EnhancedPaper>
  )
}
