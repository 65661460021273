import { useSearchParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import { showNotification } from 'summon-ui'
import { useQueryClient } from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import { AddUserReferralMutation, useAddUserReferralMutation } from '@generated/generates'
import useViewer from './viewer/useViewer'

const useReferral = () => {
  const [searchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const referralCodeParams = searchParams.get('referral_code')
  const referralCode = referralCodeParams || Cookies.get('referral_code')
  const { viewer } = useViewer()
  const { mutateAsync, isPending, error } = useAddUserReferralMutation<AddUserReferralMutation>(graphqlClient(), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['GetUser'] })

      showNotification({
        message: 'Referral code added successfully!',
        variant: 'success'
      })
    }
  })

  const addReferral = (code: string) => {
    if (viewer?.id)
      return mutateAsync({
        userId: viewer?.id,
        referralCode: code
      })
  }
  if (referralCodeParams) {
    Cookies.set('referral_code', referralCodeParams, { secure: true, sameSite: 'strict', expires: 1 })
  }

  return { isPending, referralError: (error as any)?.response?.errors?.[0].message, addReferral, referralCode }
}

export default useReferral
