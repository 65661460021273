import { PropsWithChildren } from 'react'
import { useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Button, Flex, Title, useMantineColorScheme } from 'summon-ui/mantine'
import { useQueryClient } from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import { useFinishTutorialMutation } from '@generated/generates'
import { StepType, TourProvider } from '@reactour/tour'
import { buildTour, buildStyles } from './helpers.tutorial'
import usePathname from './usePathname'

const Navigation = ({
  currentStep,
  setCurrentStep,
  setIsOpen,
  steps
}: {
  currentStep: number
  setCurrentStep: (step: number) => void
  setIsOpen: (isOpen: boolean) => void
  steps: StepType[]
}) => {
  const t = useTranslation()

  const isLastStep = currentStep === steps.length - 1
  return (
    <Flex w='100%' justify='space-between' pt='lg' align='center' gap='xs'>
      <Title order={6}>
        {t('Step')} {currentStep + 1}/{steps.length}
      </Title>
      <Flex gap='sm'>
        <Button
          variant='outline'
          onClick={() => {
            setCurrentStep(currentStep - 1)
          }}
        >
          {t('Previous')}
        </Button>
        <Button
          onClick={() => {
            isLastStep ? setIsOpen(false) : setCurrentStep(currentStep + 1)
          }}
        >
          {isLastStep ? t('Close') : t('Next')}
        </Button>
      </Flex>
    </Flex>
  )
}

const TutorialProvider = ({ children }: PropsWithChildren) => {
  const queryClient = useQueryClient()
  const { colorScheme } = useMantineColorScheme()
  const isNotMobile = useMediaScreen({ breakpoint: 'mini', type: 'min' })
  // We use pathname cause for some reason I can't use react router at this point.
  // Something weird with not being in the context of the provider. So I cerated a custom one
  const pathname = usePathname()
  const { mutate } = useFinishTutorialMutation(graphqlClient(), {
    onSuccess() {
      // Refresh user to get 'hasCompletedTutorial' updated
      queryClient.invalidateQueries({
        queryKey: ['GetUser']
      })
    }
  })

  const tour = buildTour(pathname)
  return isNotMobile ? (
    <TourProvider
      position='bottom'
      showBadge={false}
      steps={tour.steps}
      className='rounded-lg !p-6'
      beforeClose={() => mutate({})}
      styles={buildStyles(colorScheme === 'dark')}
      components={{
        Navigation,
        Close: () => {
          return null
        }
      }}
      showCloseButton
      maskClassName='some'
      showNavigation
      showDots
      highlightedMaskClassName='reacttour-highlightedMask'
      disableInteraction
    >
      {children}
    </TourProvider>
  ) : (
    children
  )
}

export default TutorialProvider
