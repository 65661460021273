import { ContentHeader, ContentLayout } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { QuestsViews } from '@features/quests'

const QuestsPage = () => {
  const t = useTranslation()

  return (
    <ContentLayout renderContentHeader={() => <ContentHeader name={t('Quests')} />}>
      <QuestsViews />
    </ContentLayout>
  )
}

export default QuestsPage
