import { ReactNode, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import FallbackPage from '@pages/FallbackPage'
import useGetCategorySlug from './useGetCategorySlug'

const RedirectToSlug = ({ id }: { id: string }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isCategoryLoading, urlSlug } = useGetCategorySlug({ id })

  useEffect(() => {
    if (!isCategoryLoading && urlSlug) {
      navigate(pathname.replace(id, urlSlug), { replace: true })
    }
  }, [urlSlug, navigate])

  return null
}

interface Props {
  renderUrlSlugCase?: (urlSlug: string) => ReactNode
}

// Component to handle the parameter and decide if it’s an ID or a slug
const CategoryUrlRouterHandler = ({ renderUrlSlugCase }: Props) => {
  const { categoryIdOrSlug } = useParams()

  if (!categoryIdOrSlug) {
    return <FallbackPage />
  }

  if (/^\d+$/.test(categoryIdOrSlug)) {
    return <RedirectToSlug id={categoryIdOrSlug} />
  } else {
    return renderUrlSlugCase ? renderUrlSlugCase(categoryIdOrSlug) : <FallbackPage />
  }
}

export default CategoryUrlRouterHandler
