import { useEffect, useMemo, useState } from 'react'
import { useReadContract } from 'wagmi'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { EnrichedMetadata, getBaseSkinsMetadataList, getItemsWithMetadata } from '@api/client'
import { graphqlClient } from '@api/config'
import { useGqlQuery } from '@api/gql/gqlServices'
import { TENANT_CONFIG, TENANT_CONTRACTS } from '@config'
import { AVATAR_BOUND_ABI } from '@constants/Abi/avatarBoundAbi'
import { ITEMS_BOUND_ABI } from '@constants/Abi/itemsBoundAbi'
import {
  GetDnaItemsDocument,
  GetDnaItemsQuery,
  InventoryItem,
  useGetInventoryItemsByIdsQuery,
  useGetInventoryItemsQuery
} from '@generated/generates'
import useAvatarStore, { AvatarState, SlotType } from '@modules/inventory/hooks/useAvatarStore'
import { isStaging } from '@utils/isStaging'
import useViewer from '../viewer/useViewer'

export interface BaseSkinContractData {
  baseSkinId: string
  tokenUri: string
}

export interface ItemContractData {
  tokenId: bigint
  tokenUri: string
  amount: bigint
}

export interface InitialItemData extends ItemContractData {
  address: string
  id: string
  isEquipped: boolean
  slotType: SlotType
  chain: {
    tokenId: string
    address: string
  }
  soulbound: boolean
}

const hasOnchainItems = TENANT_CONFIG.features?.onChainItems
const hasDnaTraits = TENANT_CONFIG.features?.dnaTraits

export const DNA_TRAITS_SLOT_TYPES = [SlotType.Eyes, SlotType.Eyebrows, SlotType.Hair, SlotType.FacialHair]

interface Props {
  isDNA?: boolean
  isDNATraits?: boolean
}

const useInventoryItems = ({ isDNA = false, isDNATraits = false }: Props) => {
  const { viewer } = useViewer()
  const queryClient = useQueryClient()
  const refreshInventoryItemsWithMetadata = () =>
    queryClient.invalidateQueries({ queryKey: ['inventoryItemsWithMetadata'] })
  const refreshGetInventoryByIdsQuery = () => queryClient.invalidateQueries({ queryKey: ['GetInventoryItemsByIds'] })
  const refreshGetInventoryItemsQuery = () => queryClient.invalidateQueries({ queryKey: ['GetInventoryItems'] })

  // TODO: Check if we need to get the Items with this query
  const { data: offChainInventoryItemsData } = useGetInventoryItemsQuery(graphqlClient(), undefined, {
    enabled: !hasOnchainItems && !isDNATraits
  })

  const offChainItems = offChainInventoryItemsData?.viewer?.inventoryItems

  const { data: dnaItemsData, isLoading: isDnaItemsLoading } = useGqlQuery<GetDnaItemsQuery>(GetDnaItemsDocument, {
    enabled: !hasOnchainItems || isDNATraits
  })

  const dnaItems = dnaItemsData?.viewer?.inventoryItems

  // Base skins logic | isDNA = onboarding
  const { data: metadataUris }: { data: BaseSkinContractData[] | undefined } = useReadContract({
    address: isStaging ? TENANT_CONTRACTS.testnet.avatar : TENANT_CONTRACTS.mainnet.avatar,
    abi: AVATAR_BOUND_ABI,
    chainId: TENANT_CONFIG.network.id,
    functionName: 'getAllBaseSkins',
    query: {
      enabled: !!hasOnchainItems || isDNA
    }
  })

  const filteredContractBaseSkins: BaseSkinContractData[] | [] | undefined = metadataUris
    ?.filter((record: BaseSkinContractData) => record?.tokenUri?.includes('https'))
    .map((record) => ({
      tokenUri: record.tokenUri,
      baseSkinId: record.baseSkinId.toString()
    }))

  const { data: metadataList } = useQuery({
    queryKey: ['metadata', { baseSkinsContractData: filteredContractBaseSkins }],
    queryFn: getBaseSkinsMetadataList,
    enabled: !!filteredContractBaseSkins
  })

  const onChainBaseSkins = useMemo(
    (): InventoryItem[] | undefined =>
      metadataList
        ?.filter((record) => record?.id)
        .map(
          (record: EnrichedMetadata): InventoryItem => ({
            id: record.id,
            imageUrl: record.image,
            glbUrl: record.animation_url,
            slotType: SlotType.Body,
            isEquipped: false,
            count: 1,
            variantColor: '',
            variants: [],
            enableVariantColors: false
          })
        ),
    [metadataList]
  )

  // Base Skins Logic END

  // GOD Mode START
  // Logic to get all items from the collection if an user is Admin role
  const { data: roleData }: { data: ItemContractData[] | undefined } = useReadContract({
    address: isStaging ? TENANT_CONTRACTS.testnet.items : TENANT_CONTRACTS.mainnet.items,
    abi: ITEMS_BOUND_ABI,
    functionName: 'MINTER_ROLE',
    chainId: TENANT_CONFIG.network.id,
    query: {
      enabled: !!hasOnchainItems && !isDNA && !!viewer?.walletAddress
    }
  })

  // Get user role
  const { data: hasRole }: { data: ItemContractData[] | undefined } = useReadContract({
    address: isStaging ? TENANT_CONTRACTS.testnet.items : TENANT_CONTRACTS.mainnet.items,
    abi: ITEMS_BOUND_ABI,
    functionName: 'hasRole',
    chainId: TENANT_CONFIG.network.id,
    args: [roleData, viewer?.walletAddress as `0x${string}`],
    query: { enabled: !!hasOnchainItems && !isDNA && !!viewer?.walletAddress && !!roleData }
  })

  // Get all contract items to pass if the user has admin role
  const { data: allItemsFromContract }: { data: ItemContractData[] | undefined } = useReadContract({
    address: isStaging ? TENANT_CONTRACTS.testnet.items : TENANT_CONTRACTS.mainnet.items,
    abi: ITEMS_BOUND_ABI,
    functionName: 'getAllItemsAdmin',
    chainId: TENANT_CONFIG.network.id,
    account: viewer?.walletAddress as `0x${string}`,
    args: [viewer?.walletAddress as `0x${string}`],
    query: { enabled: !!hasOnchainItems && !isDNA && !!viewer?.walletAddress && !!hasRole }
  })
  // GOD Mode END

  // Get onchain user Inventory Items. Real items (NFTs) the user has in wallet.
  const {
    data: userOnchainItems,
    refetch: refetchUserOnchainItems
  }: { data: ItemContractData[] | undefined; refetch: any } = useReadContract({
    address: isStaging ? TENANT_CONTRACTS.testnet.items : TENANT_CONTRACTS.mainnet.items,
    abi: ITEMS_BOUND_ABI,
    chainId: TENANT_CONFIG.network.id,
    functionName: 'getAllItems',
    account: viewer?.walletAddress as `0x${string}`,
    query: { enabled: !!hasOnchainItems && !isDNA && !!viewer?.walletAddress && !hasRole }
  })

  // Onchain items data for a wallet. God mode or real user items
  const userContractItems = hasRole ? allItemsFromContract : userOnchainItems

  const userItemsIdsArray = useMemo(
    () =>
      userContractItems
        ?.filter((record: ItemContractData) => record?.tokenUri?.includes('https'))
        .map((record) => record.tokenId.toString()),
    [userContractItems]
  )

  // Retrieve item data from the database based on the NFTs owned by the user's wallet.
  const { data: userInventoryItemsDbData, refetch: refetchInventoryItemsByIds } = useGetInventoryItemsByIdsQuery(
    graphqlClient(),
    { tokenIds: userItemsIdsArray },
    { enabled: hasOnchainItems && !!userItemsIdsArray?.length }
  )

  // Enrich the initial item data with off-chain data required before obtaining the metadata
  const initialItemsData = useMemo(
    () =>
      userInventoryItemsDbData &&
      userContractItems
        ?.map((itemOnChain: ItemContractData) => {
          const itemOffChainData = userInventoryItemsDbData?.viewer?.inventoryItems?.find(
            (itemOffChain) => itemOffChain.chain?.tokenId === Number(itemOnChain?.tokenId)
          )
          if (!itemOffChainData) return null
          return {
            id: itemOffChainData?.id,
            chain: itemOffChainData?.chain,
            isEquipped: itemOffChainData?.isEquipped || false,
            slotType: itemOffChainData?.slotType,
            soulbound: itemOffChainData?.soulbound,
            tokenUri: itemOnChain.tokenUri,
            amount: itemOnChain.amount.toString()
          }
        })
        .filter((item) => !!item),
    [userInventoryItemsDbData, userContractItems]
  )

  const { data: onchainInventoryItems } = useQuery({
    queryKey: ['inventoryItemsWithMetadata', { itemsData: initialItemsData }],
    queryFn: getItemsWithMetadata,
    enabled: !!initialItemsData
  })

  const onChainItems = useMemo(
    () => (isDNA ? onChainBaseSkins : (onchainInventoryItems as InventoryItem[])),
    [onChainBaseSkins, onchainInventoryItems, isDNA]
  )

  // TODO: Remove DNA filter because the dnaItems are already filtered
  const items = useMemo(() => {
    if (isDNATraits) {
      return dnaItems?.filter((item) => DNA_TRAITS_SLOT_TYPES.includes(item.slotType as SlotType)) ?? []
    } else if (hasOnchainItems && hasDnaTraits) {
      const nonNullOnChainItems = onChainItems?.filter((item) => !!item)
      return [
        ...(nonNullOnChainItems ?? []),
        ...(dnaItems?.filter((item) => DNA_TRAITS_SLOT_TYPES.includes(item.slotType as SlotType)) ?? [])
      ]
    } else if (hasOnchainItems) {
      return onChainItems
    } else {
      return offChainItems?.filter((item) => !isDNA || item.slotType === SlotType.Body) ?? []
    }
  }, [onChainItems, dnaItems, hasOnchainItems, isDNATraits])

  const setItems = useAvatarStore((state: AvatarState) => state.setItems)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (items?.length) {
      setItems(items as InventoryItem[])
      setLoading(false)
    }
  }, [items])

  const isItemsLoading = isDNATraits ? isDnaItemsLoading : hasOnchainItems ? isDnaItemsLoading : loading

  return {
    inventoryItems: (items as InventoryItem[]) || [],
    isInventoryItemsLoading: isItemsLoading,
    refreshInventoryItemsWithMetadata,
    refreshGetInventoryByIdsQuery,
    refreshGetInventoryItemsQuery,
    refetchInventoryItemsByIds,
    refetchUserOnchainItems
  }
}

export default useInventoryItems
