import { useRef } from 'react'
import { useCameraStorage } from '@modules/avatar/store/camera.store'
import { OrbitControls } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import annotations from './annotations.json'

const CustomOrbitControls = () => {
  const { lerping, cameraMode } = useCameraStorage()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>()

  useFrame(({ camera }) => {
    if (lerping) {
      // @ts-ignore
      camera.position.lerp(annotations[cameraMode].camPos, 0.03)
      ref.current.target.lerp(annotations[cameraMode].lookAt, 0.05)
    }
  })

  return <OrbitControls makeDefault ref={ref} enableZoom={false} enablePan={false} />
}

export default CustomOrbitControls
