import { useTranslation } from 'summon-ui/intl'
import { Button } from 'summon-ui/mantine'

interface Props {
  onStartTask: () => void
  isDisabled: boolean
}

const StartButton = ({ onStartTask, isDisabled }: Props) => {
  const t = useTranslation()

  return (
    <Button data-testid='quest-details-task-button' onClick={onStartTask} miw={145} disabled={isDisabled} w='100%'>
      {t('Start')}
    </Button>
  )
}

export default StartButton
