import { useState } from 'react'
import axios from 'axios'
import { showNotification } from 'summon-ui'
import { useQueryClient } from '@tanstack/react-query'
import { getSignedUpload } from '@api/client'
import { graphqlClient } from '@api/config'
import { useUpdateCurrentUserImageMutation } from '@generated/generates'

const useAvatarDownloader = () => {
  const queryClient = useQueryClient()
  const { mutateAsync: updateUserImage } = useUpdateCurrentUserImageMutation(graphqlClient(), {})
  const [loading, setLoading] = useState(false)
  const uploadAvatar = async (canvas: HTMLCanvasElement | null, delay?: number) => {
    if (!canvas) {
      showNotification({
        variant: 'danger',
        message: 'There was an error uploading the avatar.'
      })
      return
    }
    showNotification({
      variant: 'info',
      message: 'Updating avatar...'
    })
    setLoading(true)
    const tmpCanvas = document.createElement('canvas')
    tmpCanvas.width = 300
    tmpCanvas.height = 300
    return await new Promise((resolve) =>
      setTimeout(async () => {
        const signature = await getSignedUpload()
        const photo = await new Promise((resolve) => {
          const ctx = tmpCanvas.getContext('2d')
          ctx?.drawImage(canvas, 0, 0, 300, 300)
          tmpCanvas.toBlob(resolve, 'image/png', 1)
        })
        const formData = new FormData()
        formData.append('file', photo as Blob, `user_avatar`)
        const { data } = await axios.post(signature, formData)
        await updateUserImage({ avatarUrl: data.result.variants[0] })
        queryClient.invalidateQueries({ queryKey: ['GetUser'] })
        showNotification({
          variant: 'success',
          message: 'Avatar updated successfully'
        })
        setLoading(false)
        resolve('')
      }, delay || 0)
    )
  }

  const downloadAvatar = (canvas: HTMLCanvasElement) => {
    const link = document.createElement('a')
    const canvasElement = canvas
    if (canvasElement) {
      link.setAttribute('download', 'avatar.png')
      link.setAttribute('href', canvasElement.toDataURL('image/jpg').replace('image/jpg', 'image/octet-stream'))
      link.click()
    } else {
      console.error('Canvas element is not available.')
    }
  }
  return { loading, uploadAvatar, downloadAvatar }
}

export default useAvatarDownloader
