import { useAccount } from 'wagmi'
import { useGqlQuery } from '@api/gql/gqlServices'
import { GetUserDocument, GetUserQuery } from '@generated/generates'
import { getJWT } from '@utils/auth'

const useViewer = () => {
  const { isConnected } = useAccount()
  const hasJWT = !!getJWT()
  const isAuthenticated = isConnected && hasJWT
  const { data, isPending, isFetching, status, refetch } = useGqlQuery<GetUserQuery>(GetUserDocument, undefined, {
    enabled: isAuthenticated
  })

  return {
    isLoading: isPending && isAuthenticated,
    status,
    isAuthenticated,
    isFetching,
    refetch,
    viewer: status === 'success' ? data.viewer : null
  }
}

export default useViewer
