import { Fragment, useEffect, useMemo, useState } from 'react'
import { QuestBody, useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Stack, Divider } from 'summon-ui/mantine'
import useLootdropTerms from '@features/lootdrops/hooks/useLootdropTerms'
import { TaskWithQuestId } from '@features/quests/types'
import { Quest } from '@generated/generates'
import { useViewer } from '@hooks'
import { calculateQuestCompletionInfo } from '@utils/questsHelpers'
import QuestTask from './QuestTask'

type QuestContainerProps = {
  quest: Quest
  lootdropId?: string | undefined
  isLocked?: boolean
}

export const SUBTITLE = 'Complete the tasks below to complete this quest'
export const TASKS_TITLE = 'Quests'
export const OVERVIEW_TITLE = 'Overview'
export const PROGRESS_TITLE = 'Progression'

const QuestContainer = ({ quest, lootdropId, isLocked = false }: QuestContainerProps) => {
  const t = useTranslation()
  const isMobile = useMediaScreen({ breakpoint: 'mini', type: 'max' })
  const { viewer, isAuthenticated } = useViewer()
  const tasksWithQuestId: TaskWithQuestId[] | undefined = quest?.tasks?.map((task) => ({ ...task, questId: quest.id }))
  const { userAcceptedLootdropTerms } = useLootdropTerms({ lootdropId: lootdropId })
  const isQuestLocked = isLocked || !isAuthenticated || (!!viewer && !!quest && quest?.entryRank > viewer?.level)
  const [isOpen, setIsOpen] = useState(!isQuestLocked)

  useEffect(() => {
    setIsOpen(!isQuestLocked)
  }, [isQuestLocked])

  const { completionPercentage, totalXP, earnedXP } = useMemo(() => {
    return calculateQuestCompletionInfo(quest.tasks)
  }, [quest])

  if (!quest) return null

  const totalActionsXp = `${earnedXP}/${totalXP} ${t('XP')}`

  return (
    <Stack gap='lg'>
      <QuestBody
        name={quest.name}
        description={quest.description!}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        openedTitle={t('Actions')}
        isLocked={isQuestLocked}
        isMobile={isMobile}
        isLootdrop={!!lootdropId}
        {...(isAuthenticated && totalXP > 0 ? { totalActionsXp: totalActionsXp } : {})}
        {...(quest?.earnXP > 0 && { questXp: quest.earnXP })}
        {...(!!quest?.earnCredits && { questCredits: quest.earnCredits })}
        {...(completionPercentage > 0 ? { completionPercentage } : {})}
      >
        {tasksWithQuestId.map((task, index) => (
          <Fragment key={task.id}>
            <QuestTask
              task={task}
              isMobileVersion={!!isMobile}
              isQuestBlocked={isQuestLocked}
              lootdropId={lootdropId}
              userAcceptedLootdropTerms={userAcceptedLootdropTerms}
            />
            {index !== tasksWithQuestId.length - 1 && <Divider my='xs' />}
          </Fragment>
        ))}
      </QuestBody>
    </Stack>
  )
}

export default QuestContainer
