/* eslint-disable @typescript-eslint/no-explicit-any */
import * as THREE from 'three'
import { Vector3 } from 'three'
import { CDN_URL } from '@constants/names'
import { Euler } from '@react-three/fiber'
import { TENANT_CONFIG } from '../config'
import { ArtStyleEnum } from '../config/enum'

// TODO: Remove this when the backend is connected
export const getModelURLFromPinata = (itemType: string, counter: number, fileNameOverride?: string) => {
  let variantFolder = `${itemType.toLowerCase()}_${counter}`
  if (fileNameOverride) variantFolder = `${fileNameOverride.toLowerCase()}_${counter}`
  let fileName = `${itemType.toLowerCase()}${counter}_color1.glb`
  if (fileNameOverride) fileName = `${fileNameOverride.toLowerCase()}${counter}_color1.glb`
  return `${CDN_URL}/${itemType}/glb/${variantFolder}/${fileName}`
}

export const getImageModelURLFromPinata = (itemType: string, counter: number, fileNameOverride?: string) => {
  let variantFolder = `${itemType.toLowerCase()}_${counter}`
  if (fileNameOverride) variantFolder = `${fileNameOverride.toLowerCase()}_${counter}`

  // TODO: Remove _image1 to get the glb for each color
  let fileName = `${itemType.toLowerCase()}${counter}_image1.png`
  if (fileNameOverride) fileName = `${fileNameOverride.toLowerCase()}${counter}_image1.png`
  return `${CDN_URL}/${itemType}/glb/${variantFolder}/${fileName}`
}

export interface INodeMesh {
  name: string
  geometry: THREE.BufferGeometry
  material: THREE.MeshStandardMaterial
  position: Vector3
  rotation: Euler
  scale: Vector3
  animations: THREE.AnimationClip[]
}

export const getCorrectNodeData = (node: THREE.Mesh) => {
  let position: Vector3 = node.position as Vector3
  let rotation = node.rotation
  const scale = node.scale
  const meshMaterialName = node.material as THREE.MeshStandardMaterial
  const animations = node.animations
  if (node.parent && node.parent.type === 'Object3D' && node.parent.isObject3D) {
    const { position: parentPosition, rotation: parentRotation } = getCorrectNodeData(node.parent as THREE.Mesh)
    position = parentPosition
    rotation = parentRotation
  }
  position = position.x + position.y + position.z !== 0 ? position : new Vector3()

  return {
    position,
    scale,
    meshMaterialName,
    rotation,
    animations
  }
}

export const getNodeData = (nodes: object, materials: THREE.MeshStandardMaterial) => {
  const meshes: INodeMesh[] = []

  Object.keys(nodes)?.map((key) => {
    //@ts-ignore-next-line
    const currentNode = nodes[key] as THREE.Mesh
    if (currentNode.isObject3D && currentNode.type === 'Mesh') {
      const { position, scale, meshMaterialName, rotation, animations } = getCorrectNodeData(currentNode)
      meshes.push({
        name: key,
        geometry: currentNode.geometry,
        //@ts-ignore
        material: materials[meshMaterialName.name],
        position,
        rotation,
        scale,
        animations
      })
    }
  })

  return meshes
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function getImageFromCanvas(canvas: any, _width = 300, height = 500, mime = 'image/png', quality = 0.8) {
  if (TENANT_CONFIG.artStyle === ArtStyleEnum.Art3D) {
    return new Promise((resolve) => {
      const tmpCanvas = document.createElement('canvas')
      tmpCanvas.width = 300
      tmpCanvas.height = 300

      const ctx = tmpCanvas.getContext('2d')
      ctx?.drawImage(canvas, 0, 0, 300, 300)
      tmpCanvas.toBlob(resolve, mime, quality)
    })
  }
  return await canvas.getStage().toBlob()
}
