import { RefObject } from 'react'
import { TENANT_CONFIG } from '@config'
import ShareAvatar from './ShareAvatar'
import ShareButton from './ShareButton'
import { SharingItem, SocialNetwork } from './types'

const useShare = (sharingItem: SharingItem) => {
  const handleDownloadImg = (name: string, canvasRef?: RefObject<HTMLCanvasElement>) => () => {
    if (!canvasRef?.current) {
      return
    }

    const linkToImgUrl = document.createElement('a')
    linkToImgUrl.setAttribute('download', name)
    linkToImgUrl.setAttribute(
      'href',
      canvasRef.current.toDataURL('image/jpg').replace('image/jpg', 'image/octet-stream')
    )
    linkToImgUrl.click()
  }

  const getSharingInfo = (canvasRef?: RefObject<HTMLCanvasElement>) => {
    // Defaults to 'avatar'
    const currentDomain = window?.location?.origin
    const info = {
      Component: <ShareAvatar ref={canvasRef} />,
      postText: `Flexing my new avatar from ${TENANT_CONFIG.name}. If you want your own, go to ${currentDomain}`,
      downloadSharingImg: handleDownloadImg('avatar', canvasRef)
    }

    switch (sharingItem) {
      default: // Defaults to 'avatar'
        break
    }

    return info
  }

  const renderSharingButton = (network: SocialNetwork) => {
    let Component = null
    let url
    switch (network) {
      case 'X':
        url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(getSharingInfo().postText)}`
        Component = <ShareButton url={url} />
        break
      default:
        break
    }

    return Component
  }

  return {
    getSharingInfo,
    renderSharingButton
  }
}

export default useShare
