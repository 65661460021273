import { useTranslation } from 'summon-ui/intl'
import { Modal, Button, Title, Text, Box, Image, Stack, Group, Carousel, List, Card } from 'summon-ui/mantine'
import useLootdropOnboarding from '../hooks/useLootdropOnboarding'

const SLIDE_1_HEIGHT = 420
const SLIDE_2_HEIGHT = 560

interface Props {
  open: boolean
  onClose: () => void
}

const LootdropOnboarding = ({ open, onClose }: Props) => {
  const t = useTranslation()
  const { slide, setSlide, handleClickActionButton, isCompletingOnboarding } = useLootdropOnboarding({
    callback: onClose
  })
  const isFirstSlide = slide === 0

  const renderStepOne = () => (
    <Stack justify='center'>
      <Box>
        <Image src='/images/lootdrop-onboarding-step-1.jpg' mb='md' alt='onboarding step 1' />
        <Title order={3} ta='center'>
          {t('Welcome to Lootdrops')}
        </Title>
        <Text ta='center'>
          {t(
            'Dive into a revolutionary Web3 experience where your participation boosts your chances to win exciting token rewards. Get started and join the fun today!'
          )}
        </Text>
      </Box>
    </Stack>
  )

  const renderStepTwo = () => (
    <Stack justify='center'>
      <Box>
        <Image src='/images/lootdrop-onboarding-step-2.jpg' mb='md' />
        <Title order={3} ta='center'>
          {t('Why Join Lootdrops?')}
        </Title>
        <Text ta='center'>{t('Complete quests to increase your multiplier')}</Text>
      </Box>
      <Card withBorder p='md'>
        <List size='sm' pr='xs' spacing='6'>
          {BULLETS_CARD.map((bullet) => (
            <List.Item key={bullet.title}>
              <Text fw={800} component='span'>
                {t(bullet.title)}{' '}
              </Text>
              {t(bullet.description)}
            </List.Item>
          ))}
        </List>
      </Card>
    </Stack>
  )

  return (
    <Modal opened={open} onClose={onClose} withCloseButton={false} radius='md'>
      <Box p='xs'>
        <Carousel
          draggable={false}
          withIndicators
          withControls={false}
          initialSlide={slide}
          height={isFirstSlide ? SLIDE_1_HEIGHT : SLIDE_2_HEIGHT}
          onSlideChange={(index) => setSlide(index)}
          styles={{
            indicator: { backgroundColor: 'red', borderRadius: '100%', width: 8, height: 8 }
          }}
        >
          <Carousel.Slide>{renderStepOne()}</Carousel.Slide>
          <Carousel.Slide>{renderStepTwo()}</Carousel.Slide>
        </Carousel>
        <Group justify='space-between' wrap='nowrap' mt='md'>
          <Button
            variant='outline'
            onClick={onClose}
            fullWidth
            loading={isCompletingOnboarding}
            disabled={isCompletingOnboarding}
          >
            {t('Close')}
          </Button>
          <Button
            fullWidth
            onClick={handleClickActionButton}
            loading={isCompletingOnboarding}
            disabled={isCompletingOnboarding}
          >
            {isFirstSlide ? t('Next') : t('Get Started')}
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}

export default LootdropOnboarding

const BULLETS_CARD = [
  {
    title: 'Earn Real Rewards:',
    description: 'Win valuable token rewards by participating.'
  },
  {
    title: 'Exciting Quests:',
    description: 'Engage in fun and challenging quests.'
  },
  {
    title: 'Exclusive Opportunities:',
    description: 'Discover cool ecosystems, gain access to unique events, and earn special items.'
  }
]
