import { Spinner } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Center, Stack, Group } from 'summon-ui/mantine'
import { graphqlClient } from '@api/config'
import { useGetBadgesQuery } from '@generated/generates'
import NoItemsMessage from '../NoItemsMessage'
import Badge from './Badge'

const NO_BADGES_TITLE = 'No Badges Unlocked'
const NO_BADGES_SUBTITLE =
  'Badges are unlocked when you complete certain quests, missions, or attend special events. Keep questing and stay involved'

const Badges = () => {
  const t = useTranslation()
  const { data: badgesData, isLoading, status } = useGetBadgesQuery(graphqlClient())

  if (isLoading) {
    return (
      <Center className='h-80'>
        <Spinner isFull />
      </Center>
    )
  }

  if (status === 'error') {
    return <Center className='h-80'>Something wrong happened...</Center>
  }

  const badges = badgesData?.viewer?.badges

  return (
    <Stack h='100%'>
      {badges?.length ? (
        <Group mt='lg'>
          {badgesData?.viewer?.badges?.map(({ id, imageUrl, name, createdAt }, index) => (
            <Badge key={`${id}-${index}`} imageUrl={imageUrl} name={name} createdAt={createdAt} />
          ))}
        </Group>
      ) : (
        <Stack>
          <NoItemsMessage title={t(NO_BADGES_TITLE)} message={t(NO_BADGES_SUBTITLE)} />
        </Stack>
      )}
    </Stack>
  )
}

export default Badges
