import { TenantImageEnum, useAssetsContext, BadgePrize } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import {
  Flex,
  Text,
  Avatar,
  Stack,
  Title,
  Badge,
  Box,
  Group,
  TypographyStylesProvider,
  Center
} from 'summon-ui/mantine'
import { ReadMore } from '@components'
import ActionButton from '@features/quests/components/ActionButton'
import { TaskWithQuestId } from '@features/quests/types'

interface Props {
  task: TaskWithQuestId
  isMobileVersion: boolean
  isQuestBlocked: boolean
  lootdropId?: string | undefined
  userAcceptedLootdropTerms?: boolean
}

const QuestTask = ({ task, isMobileVersion, isQuestBlocked, lootdropId, userAcceptedLootdropTerms }: Props) => {
  const t = useTranslation()
  const { getTenantImage } = useAssetsContext()
  const actionPlaceholder = getTenantImage(TenantImageEnum.ActionPlaceholder)

  const renderBadges = () => (
    <Group gap='xs'>
      {task?.earnXP > 0 && <BadgePrize variant='xp' info={`${task.earnXP} XP`} />}
      {!!task?.earnCredits && (
        <BadgePrize variant='credits' info={`${new Intl.NumberFormat('en-US').format(task.earnCredits)} CREDITS`} />
      )}
      {task.isRequired && (
        <Badge size='lg' variant='outline' color='gray.1'>
          {t('Required')}
        </Badge>
      )}
    </Group>
  )

  return (
    <Stack key={task.id} gap='lg'>
      <Flex gap='md' w='100%' justify='space-between' align='center'>
        <Flex gap={isMobileVersion ? 'md' : 'lg'} direction={isMobileVersion ? 'column' : 'row'}>
          {isMobileVersion ? (
            <Avatar alt='task' src={task.avatarUrl || actionPlaceholder} size='lg' />
          ) : (
            <Center>
              <Avatar alt='task' src={task.avatarUrl || actionPlaceholder} size='lg' />
            </Center>
          )}
          <Stack gap='xs'>
            <Flex direction={isMobileVersion ? 'column' : 'row'} gap='xs' w='100%'>
              <Title data-testid='quest-details-task-label' order={5}>
                {task.name}
              </Title>
            </Flex>
            {task.instructions && (
              <ReadMore>
                <TypographyStylesProvider>
                  <Text
                    data-testid='quest-details-instructions-label'
                    dangerouslySetInnerHTML={{ __html: task.instructions }}
                  />
                </TypographyStylesProvider>
              </ReadMore>
            )}
            {renderBadges()}
          </Stack>
        </Flex>
        {!isMobileVersion && (
          <Box>
            <ActionButton
              testId='quest-details-task-button'
              task={task}
              disabled={isQuestBlocked}
              lootdropId={lootdropId}
              userAcceptedLootdropTerms={userAcceptedLootdropTerms}
            />
          </Box>
        )}
      </Flex>
      {isMobileVersion && (
        <Stack w='100%'>
          <ActionButton
            testId='quest-details-task-button'
            task={task}
            disabled={isQuestBlocked}
            lootdropId={lootdropId}
            userAcceptedLootdropTerms={userAcceptedLootdropTerms}
          />
        </Stack>
      )}
    </Stack>
  )
}

export default QuestTask
