import { useEffect } from 'react'
import { useMediaScreen } from 'summon-ui'
import { Box, Group } from 'summon-ui/mantine'
import { useViewer } from '@hooks'
import OnboardingSidebar from '../../components/OnboardingSidebar'
import { useOnboardingStorage } from '../../onboarding.store'
import Mint from '../Mint'
import EquipDNATraits from './EquipDNATraits.view'

const STEPS = [
  { name: 'Create Avatar', description: 'Create your Avatar and mint', id: 0 },
  { name: 'Select Traits', description: 'Select DNA traits to save to IPFS', id: 1 }
]
export const Game7OnboardingView = () => {
  const { viewer } = useViewer()
  const { step, setStep } = useOnboardingStorage()
  const isMinLaptop = useMediaScreen({ breakpoint: 'laptop', type: 'min' })

  useEffect(() => {
    if (viewer?.skinId) setStep(1)
    else if (viewer) setStep(0)
  }, [viewer])

  return (
    <Group h='100%' w='100%' wrap='nowrap' gap='0'>
      {isMinLaptop && <OnboardingSidebar steps={STEPS} />}
      <Box p={{ base: 20, md: 32 }} w='100%' h='100%' pos='relative'>
        {
          {
            0: <Mint />,
            1: <EquipDNATraits />
          }[step]
        }
      </Box>
    </Group>
  )
}
