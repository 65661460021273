import { forwardRef, ReactNode } from 'react'
import { Box, Drawer, Flex } from 'summon-ui/mantine'
import { TENANT_CONFIG } from '@config'
import { ArtStyleEnum } from '@config/enum'
import InventoryModule from '@modules/inventory'
import AvatarPreview from '../../../modules/avatar'

interface Props {
  isDNA?: boolean
  isDNATraits?: boolean
  children?: ReactNode
}
const AvatarWithInventory = forwardRef(({ isDNA = false, isDNATraits = false, children }: Props, ref) => {
  return (
    <Flex justify='space-between' className='h-[70vh]'>
      <AvatarPreview
        className='w-1/2 max-w-[600px] mt-10 max-h-[45vh] md:max-h-full'
        previewMode={isDNATraits || TENANT_CONFIG.artStyle === ArtStyleEnum.Art2D}
        ref={ref}
        isDNA={isDNA}
        centerProps={{ zoom: 5 }}
        isDNATraits={isDNATraits}
      />
      <Drawer
        hiddenFrom='md'
        position='bottom'
        radius='lg'
        onClose={() => {
          return
        }}
        withCloseButton={false}
        size={220}
        withOverlay={false}
        opened
        lockScroll={false}
      >
        <InventoryModule hideActionMenu isMobile isDNA={isDNA} isDNATraits={isDNATraits} />
        {children}
      </Drawer>
      <Box visibleFrom='md'>
        <InventoryModule hideActionMenu isDNA={isDNA} isDNATraits={isDNATraits} />
      </Box>
    </Flex>
  )
})

AvatarWithInventory.displayName = 'AvatarWithInventory'
export default AvatarWithInventory
