import { Icon } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Button } from 'summon-ui/mantine'
import { useAccount } from 'wagmi'
import { useLogin } from '@hooks'
import { useWeb3Modal } from '@web3modal/wagmi/react'

type ConnectButtonProps = {
  isMinimal?: boolean
  isXL?: boolean
}
const ConnectButton = ({ isMinimal = false, isXL = false }: ConnectButtonProps) => {
  const t = useTranslation()
  const { isConnecting, login } = useLogin()
  const { isConnected } = useAccount()
  const { open: openConnectModal } = useWeb3Modal()

  return (
    <Button
      data-testid='connect-wallet-button'
      fullWidth={!isMinimal}
      onClick={() => (isConnected ? login() : openConnectModal())}
      loading={isConnecting}
      w={isMinimal ? 48 : '100%'}
      {...(isXL ? { size: 'xl' } : {})}
      {...(isMinimal ? { p: 0 } : {})}
    >
      {isMinimal ? <Icon name='LogIn02' color='inherit' /> : t('LOGIN / SIGN UP')}
    </Button>
  )
}

export default ConnectButton
