import { useLocation, Link } from 'react-router-dom'
import clsx from 'clsx'
import { Icon } from 'summon-ui'
import { Group, Title } from 'summon-ui/mantine'

const formatBreadcrumbName = (name: string) => {
  if (name === 'lootdrop') {
    return 'LootDrop'
  }
  // Replace hyphens and URL-encoded spaces with spaces
  return name.replace(/-/g, ' ').replace(/%20/g, ' ')
}

const Breadcrumbs = ({ lastText, exclude = [] }: { lastText?: string; exclude?: string[] }) => {
  const { pathname } = useLocation()
  const pathnames = pathname.split('/').filter(Boolean)

  return (
    <Group gap='0' align='center'>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`
        const isLast = index === pathnames.length - 1
        const formattedName = formatBreadcrumbName(name)

        return isLast ? (
          <Title key={name} size='sm'>
            {(lastText || name).replace('%20', ' ')}
          </Title>
        ) : (
          <Link key={name} to={routeTo} className={clsx('breadcrumb', exclude.includes(name) ? 'hidden' : '')}>
            <Group gap='0'>
              <Title size='sm'>{formattedName}</Title>
              <Icon name='ChevronRight' size='sm' color='dimmed' />
            </Group>
          </Link>
        )
      })}
    </Group>
  )
}

export default Breadcrumbs
