import { useState } from 'react'
import CompletedButton from '../components/CompletedButton'
import StartButton from '../components/StartButton'
import TypeformModal from './TypeformModal'

interface Props {
  typeformId: string
  userId: string
  isCompleted: boolean
  isDisabled?: boolean
}

const Typeform = ({ typeformId, userId, isCompleted, isDisabled = false }: Props) => {
  const [isCurrentlyCompleted, setIsCurrentlyCompleted] = useState(isCompleted)
  const [isTypeformModalOpen, setIsTypeformModalOpen] = useState(false)

  const handleSuccess = () => {
    setIsCurrentlyCompleted(true)
  }

  const handleStartTask = () => {
    setIsTypeformModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsTypeformModalOpen(false)
  }

  return (
    <>
      {isCurrentlyCompleted ? (
        <CompletedButton />
      ) : (
        <StartButton onStartTask={handleStartTask} isDisabled={isDisabled} />
      )}
      <TypeformModal
        typeformId={typeformId}
        isOpened={isTypeformModalOpen}
        userId={userId}
        onSuccess={handleSuccess}
        onFinish={handleCloseModal}
      />
    </>
  )
}

export default Typeform
