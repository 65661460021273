import { NavLink as RouterNavLink, useMatch, useResolvedPath } from 'react-router-dom'
import { IconName, Icon } from 'summon-ui'
import { NavLink, Title, useMantineColorScheme } from 'summon-ui/mantine'
import { BOTTOM_MENU } from '@layouts/DashboardLayout/constants'

const BottomNavigationMenu = ({ isSmall }: { isSmall: boolean }) => {
  const { colorScheme } = useMantineColorScheme()

  return (
    <>
      {BOTTOM_MENU.map(({ iconName, ...link }) => {
        const resolvedPath = useResolvedPath(link.to)
        const match = useMatch({ path: resolvedPath.pathname, end: true })
        const isActive = !!match

        return (
          <NavLink
            key={link.name}
            component={RouterNavLink}
            to={link.to}
            data-testid={`navigation-menu-${link.name}-link`}
            leftSection={<Icon c={colorScheme === 'light' ? 'gray.7' : 'white'} name={iconName as IconName} />}
            w={isSmall ? 50 : '100%'}
            tt='uppercase'
            active={isActive}
            {...(isSmall
              ? {}
              : {
                  label: (
                    <Title order={6} tt='uppercase'>
                      {link.name}
                    </Title>
                  )
                })}
          />
        )
      })}
    </>
  )
}

export default BottomNavigationMenu
