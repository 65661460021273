import { useGqlQuery } from '@api/gql/gqlServices'
import { GetRewardsRedeemedDocument, GetRewardsRedeemedQuery, RewardRedeemed } from '@generated/generates'

interface Props {
  redeemed?: boolean
  active?: boolean
}

const useRedeemables = ({ redeemed, active }: Props = {}) => {
  // Dynamically create the input object, excluding undefined properties
  const input: { redeemed?: boolean; active?: boolean } = {}

  if (redeemed !== undefined) {
    input.redeemed = redeemed
  }

  if (active !== undefined) {
    input.active = active
  }

  const {
    data: rewardsRedeemData,
    isPending: isRewardsRedeemablesLoading,
    status: rewardsRedeemablesStatus
  } = useGqlQuery<GetRewardsRedeemedQuery>(GetRewardsRedeemedDocument, {
    input // Always pass input, which will only include defined properties
  })

  const redeemablesArray =
    rewardsRedeemablesStatus === 'success' ? (rewardsRedeemData?.rewardsRedeemed as RewardRedeemed[]) : null

  return {
    rewardsRedeemables: redeemablesArray,
    isRewardsRedeemablesLoading,
    rewardsRedeemablesStatus
  }
}

export default useRedeemables
