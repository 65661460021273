import { useState } from 'react'
import { Spinner, showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Center } from 'summon-ui/mantine'
import { graphqlClient } from '@api/config'
import { TENANT_CONFIG } from '@config'
import SolanaForm from '@features/settings/views/SolanaForm'
import { useUpdateCurrentUserSettingsMutation } from '@generated/generates'
import { useViewer } from '@hooks'
import { SettingItem } from '../components/SettingItem'
import { SettingsHeader } from '../components/SettingsHeader'

const walletSettings = TENANT_CONFIG?.features?.walletSettings

const WalletView = () => {
  const t = useTranslation()
  const { viewer } = useViewer()
  const [isUpdatingSolana, setIsUpdatingSolana] = useState(false)
  const { mutateAsync: updateUserSettings } = useUpdateCurrentUserSettingsMutation(graphqlClient())

  const handleSubmit = (data: any) => {
    // eslint-disable-next-line no-prototype-builtins
    setIsUpdatingSolana(true)
    updateUserSettings(data)
      .then(() => {
        // refetch()
        showNotification({
          variant: 'success',
          message: 'Wallet updated successfully'
        })
      })
      .catch(() => {
        showNotification({
          variant: 'danger',
          message: 'Unable to save wallet'
        })
      })
      .finally(() => {
        setIsUpdatingSolana(false)
      })
  }

  const renderSolanaWallet = () => {
    return (
      <>
        {viewer ? (
          <SolanaForm
            solanaWalletAddress={(viewer?.customData?.solanaWallet?.address as string) || undefined}
            onSubmit={handleSubmit}
            isUpdating={isUpdatingSolana}
          />
        ) : (
          <Center className='h-32'>
            <Spinner />
          </Center>
        )}
      </>
    )
  }

  return (
    <>
      <SettingsHeader title={t('Wallets')} subTitle={t('Connect your various wallets to your tenant')} />
      <SettingItem
        name={t('Connected Solana Wallet')}
        description={t('You have connected the following Solana wallet to your account')}
      >
        {walletSettings?.solana && renderSolanaWallet()}
      </SettingItem>
    </>
  )
}

export default WalletView
