import { Group, Stack, Title, Text, Paper } from 'summon-ui/mantine'
import { CraftingRecipe } from '@generated/generates'

type BlueprintCardProps = {
  recipe: CraftingRecipe
  onClick?: () => void
}

const BlueprintCard = ({ recipe, onClick }: BlueprintCardProps) => {
  return (
    <Paper
      withBorder
      radius='md'
      h={124}
      w='100%'
      p='sm'
      className={'hover:border-brand cursor-pointer'}
      onClick={onClick}
    >
      <Group h='100%'>
        <Stack gap='0'>
          <Title order={4} ta='left'>
            {recipe.name}
          </Title>
          <Text ta='left'>{recipe.description}</Text>
        </Stack>
      </Group>
    </Paper>
  )
}

export default BlueprintCard
