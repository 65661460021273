import { useTimeout } from 'summon-ui/mantine'
import { graphqlClient } from '@api/config'
import { useApplyCreditsRetroactivelyMutation } from '@generated/generates'

const useApplyCreditsRetroactively = () => {
  const { start: applyCreditsRetroactively } = useTimeout(() => apply(), 1000)

  const { mutateAsync: applyCreditsRetroactivelyMutation } = useApplyCreditsRetroactivelyMutation(graphqlClient(), {})

  const apply = async () => {
    try {
      await applyCreditsRetroactivelyMutation({})
    } catch (error) {
      console.error('Error applying credits retroactively: ', error)
    }
  }
  return applyCreditsRetroactively
}

export default useApplyCreditsRetroactively
