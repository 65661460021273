import { ContentHeader } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Box } from 'summon-ui/mantine'
import { HistoryViews } from '@features/history'
import { withProtectedRoute } from '@hocs'

const HistoryPage = () => {
  const t = useTranslation()

  return (
    <>
      <ContentHeader name={t('History')} />
      <Box className='p-4 sm:p-8 h-[calc(100vh-100px)]'>
        <HistoryViews />
      </Box>
    </>
  )
}

export default withProtectedRoute(HistoryPage)
