import { useQueryClient } from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import {
  useCompleteUserOnboardingMutation,
  useEquipInventoryItemsMutation,
  useSaveAvatarMutation
} from '@generated/generates'

const useOnboardingUtils = () => {
  const queryClient = useQueryClient()
  const refreshUserQuery = () => queryClient.invalidateQueries({ queryKey: ['GetUser'] })
  const refreshInventoryItemsQuery = () => queryClient.invalidateQueries({ queryKey: ['GetInventoryItems'] })

  const { mutateAsync: saveAvatar, isPending: isSavingAvatar } = useSaveAvatarMutation(graphqlClient(), {
    onSuccess() {
      refreshUserQuery()
      refreshInventoryItemsQuery()
    }
  })

  //TODO; Use this from a reused hook to use it in the home inventory and onboarding
  const { mutateAsync: equipInventoryItemsBatch, isPending: isEquipping } = useEquipInventoryItemsMutation(
    graphqlClient(),
    {
      onSuccess() {
        refreshInventoryItemsQuery()
      }
    }
  )

  const { mutateAsync: completeOnboarding, isPending: isCompletingOnboarding } = useCompleteUserOnboardingMutation(
    graphqlClient(),
    {
      onSuccess: () => refreshUserQuery()
    }
  )

  return {
    refreshUserQuery,
    refreshInventoryItemsQuery,
    saveAvatar,
    isSavingAvatar,
    equipInventoryItemsBatch,
    isEquipping,
    completeOnboarding,
    isCompletingOnboarding
  }
}

export default useOnboardingUtils
