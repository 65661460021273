import { Ref, Suspense, forwardRef, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { Spinner } from 'summon-ui'
import { CustomCameraControls, CustomOrbitControls } from '@components'
import { TENANT_CONFIG } from '@config'
import { useOnboardingStorage } from '@features/onboarding/onboarding.store'
import { InventoryItem } from '@generated/generates'
import { useViewer } from '@hooks'
import { SlotType } from '@modules/inventory/hooks/useAvatarStore'
import { Environment, useGLTF } from '@react-three/drei'

/* eslint-disable react/no-unknown-property */
import { Canvas } from '@react-three/fiber'
import { useCameraStorage } from '../store/camera.store'
import Mesh from './Mesh'

type Props = {
  className?: string
  items: InventoryItem[]
  preview: boolean
  isEditor?: boolean
  background?: string | undefined
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  centerProps: any
}

const game7 = TENANT_CONFIG.name === 'Game7'

const Avatar3D = forwardRef(({ className, background, items, preview, centerProps, isEditor = false }: Props, ref) => {
  const { setLerping } = useCameraStorage()
  const avatarRef = useRef(null)
  const { viewer, isLoading: isViewerLoading } = useViewer()
  const { selectedOnboardingSkin } = useOnboardingStorage()
  const [loadingAssets, setLoadingAssets] = useState(true)

  const skin = viewer?.skinId || selectedOnboardingSkin?.glbUrl
  useEffect(() => {
    if (loadingAssets && items && skin) {
      useGLTF.preload(skin)
      items.filter((item) => item.isEquipped).forEach((item) => useGLTF.preload(item.glbUrl))
      setLoadingAssets(false)
    }
  }, [items, loadingAssets, skin])

  if (isViewerLoading) return <Spinner />

  return (
    <Suspense fallback={<Spinner />}>
      <Canvas
        camera={{ zoom: centerProps?.zoom || (game7 ? 4.7 : 7), fov: 65 }}
        data-testid='avatar-preview'
        onPointerDown={() => setLerping(false)}
        onWheel={() => setLerping(false)}
        className={clsx(className, ' cursor-grabbing ')}
        ref={ref as Ref<HTMLCanvasElement>}
        frameloop='demand'
        resize={{ debounce: 0 }}
        gl={{ preserveDrawingBuffer: true }}
        shadows
      >
        {!isEditor && (game7 || !preview ? <CustomCameraControls rotate={!preview} /> : <CustomOrbitControls />)}
        <Environment files='/avatar-environment-apartment.hdr' />
        <pointLight intensity={0.3} position={[0, 2, 0]} castShadow />
        <pointLight intensity={1} position={[-4, 0, 0]} castShadow />
        <pointLight intensity={2} position={[0, 1, 0]} castShadow />
        {background && <color attach='background' args={[background]} />}
        <group ref={avatarRef} position={centerProps?.position || [0, game7 ? -0.65 : 0, 0]}>
          {!loadingAssets && skin && (
            <>
              <Mesh uri={skin} renderOrder={0} />
              {items
                ?.filter((item) => item.isEquipped)
                // Check if some Headwear is equipped to hide Hair because it's DNA trait
                ?.filter((equippedItem) => {
                  const isHeadwearEquipped = items.some(
                    (item) => item.isEquipped && item.slotType === SlotType.Headwear
                  )
                  return !(equippedItem.slotType === SlotType.Hair && isHeadwearEquipped)
                })
                ?.map((item, index) => (
                  <Mesh key={`avatar-equipped-item-${item.id}-${index}`} uri={item.glbUrl} color={item.variantColor} />
                ))}
            </>
          )}
        </group>
      </Canvas>
    </Suspense>
  )
})

Avatar3D.displayName = 'Avatar3D'
export default Avatar3D
