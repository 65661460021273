import { useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Modal, Button, Title, Text, Box, Image, Stack, Group, Checkbox, Carousel, Flex } from 'summon-ui/mantine'
import useCreditsOnboarding from '../hooks/useCreditsOnboarding'

const INTRO_VIDEO = 'https://assets.summon.xyz/game7/portal/videos/credits_onboarding.mp4'
const INTRO_IMAGE = '/images/credits-onboarding.jpg'

interface Props {
  open: boolean
  onClose: () => void
}

const CreditsOnboarding = ({ open, onClose }: Props) => {
  const t = useTranslation()
  const { handleClickActionButton, slide, setSlide, checked, setChecked, videoLoaded, setVideoLoaded } =
    useCreditsOnboarding({
      callback: onClose
    })
  const isFirstSlide = slide === 0
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'mini' })
  const slideOneHeight = isMobile ? 380 : 390
  const slideTwoHeight = isMobile ? 280 : 250

  const renderStepOne = () => (
    <Box>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        width='100%'
        autoPlay
        muted
        onLoadedData={() => setVideoLoaded(true)}
        style={{ display: videoLoaded ? 'block' : 'none' }}
      >
        <source src={INTRO_VIDEO} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
      {!videoLoaded && <Image src={INTRO_IMAGE} mb='md' alt='credits onboarding' />}

      <Title order={3} ta='center' mt='md'>
        Get Credit!
      </Title>
      <Text ta='center'>
        Credits are a measure of your contribution to Game7. You can earn credits by questing on Game7, completing
        HyperPlay quests, and referring friends.
      </Text>
    </Box>
  )

  const renderStepTwo = () => (
    <Stack justify='center'>
      <Title order={3} ta='center'>
        {t('Terms & Conditions')}
      </Title>
      <Text ta='center'>
        Please confirm that ‘I am not located in the U.S. or a U.S. persons.’ Those currently located in the U.S. or
        U.S. persons are not permitted to participate in the credits campaign due to the U.S. regulatory environment.
      </Text>
      <Flex justify='center'>
        <Checkbox
          label={<Text fw={700}>I confirm the above statement is true</Text>}
          checked={checked}
          onChange={(event) => setChecked(event.currentTarget.checked)}
        />
      </Flex>
    </Stack>
  )

  return (
    <Modal opened={open} onClose={onClose} withCloseButton={false} radius='md' closeOnClickOutside={false}>
      <Box>
        <Carousel
          draggable={false}
          withIndicators
          withControls={false}
          initialSlide={slide}
          height={isFirstSlide ? slideOneHeight : slideTwoHeight}
          onSlideChange={(index) => setSlide(index)}
          styles={{
            indicator: { backgroundColor: 'red', borderRadius: '100%', width: 8, height: 8 }
          }}
        >
          <Carousel.Slide>{renderStepOne()}</Carousel.Slide>
          <Carousel.Slide>{renderStepTwo()}</Carousel.Slide>
        </Carousel>
        <Group justify='space-between' wrap='nowrap'>
          <Button fullWidth onClick={handleClickActionButton} disabled={!isFirstSlide && !checked}>
            {isFirstSlide ? t('Next') : t('Get Started')}
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}

export default CreditsOnboarding
