import { useState } from 'react'
import { showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Loader, Center } from 'summon-ui/mantine'
import { graphqlClient } from '@api/config'
import { useUpdateCurrentUserSettingsMutation } from '@generated/generates'
import { useViewer } from '@hooks'
import { SettingItem } from '../components/SettingItem'
import { SettingsHeader } from '../components/SettingsHeader'
import EmailForm, { EmailFormValues } from './EmailForm'
import NameForm, { NameFormValues } from './NameForm'

const ProfileView = () => {
  const t = useTranslation()
  const { viewer, refetch } = useViewer()
  const [isUpdatingName, setIsUpdatingName] = useState(false)
  const [isUpdatingEmail, setIsUpdatingEmail] = useState(false)
  const { mutateAsync: updateUserSettings } = useUpdateCurrentUserSettingsMutation(graphqlClient())

  const handleSubmit = (data: NameFormValues | EmailFormValues) => {
    // eslint-disable-next-line no-prototype-builtins
    const isUpdatingEmail = data.hasOwnProperty('emailAddress')
    isUpdatingEmail ? setIsUpdatingEmail(true) : setIsUpdatingName(true)
    updateUserSettings(data)
      .then(() => {
        refetch()
        showNotification({
          variant: 'success',
          message: 'Profile updated successfully'
        })
      })
      .catch(() => {
        showNotification({
          variant: 'danger',
          message: 'Unable to update profile'
        })
      })
      .finally(() => {
        isUpdatingEmail ? setIsUpdatingEmail(false) : setIsUpdatingName(false)
      })
  }

  return (
    <>
      <SettingsHeader title={t('User profile')} subTitle={t('Update your profile details')} />
      {viewer ? (
        <>
          <SettingItem name='Name' description={t('Update your profile name')}>
            <NameForm
              firstName={viewer.firstName || undefined}
              lastName={viewer.lastName || undefined}
              username={viewer.username || undefined}
              onSubmit={handleSubmit}
              isUpdating={isUpdatingName}
            />
          </SettingItem>
          <SettingItem name='Email' description={t('Update your email')}>
            <EmailForm
              emailAddress={viewer.emailAddress || undefined}
              onSubmit={handleSubmit}
              isUpdating={isUpdatingEmail}
            />
          </SettingItem>
        </>
      ) : (
        <Center className='h-32'>
          <Loader type='bars' />
        </Center>
      )}
    </>
  )
}

export default ProfileView
