import { Link } from 'react-router-dom'
import { QuestCategoryCard } from 'summon-ui'
import { Category } from '@generated/generates'
import { resolveVariant } from '@utils/questsHelpers'

interface Props {
  category: Category
}

const CategoryCard = ({ category }: Props) => {
  const { id, urlSlug, name, description, imageUrl } = category
  const questVariant = resolveVariant(category?.completionPercentage || 0)

  const Card = (
    <QuestCategoryCard
      key={id}
      name={name}
      variant={questVariant}
      imgUrl={imageUrl ?? undefined}
      description={description || ''}
      {...(category?.completionPercentage ? { completionPercentage: category?.completionPercentage } : {})}
      {...(category?.totalXP ? { totalXP: category?.totalXP } : {})}
      {...(category?.totalCredits ? { totalCredits: category?.totalCredits } : {})}
    />
  )

  return (
    <Link key={id} to={`${urlSlug}`}>
      {Card}
    </Link>
  )
}

export default CategoryCard
