import { useRef, useState } from 'react'
import clsx from 'clsx'
import { Icon } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Button, Center, ColorInput, Flex, Stack, useMantineColorScheme } from 'summon-ui/mantine'
import { useAvatarDownloader } from '@hooks'
import AvatarPreview from '@modules/avatar'

const DEFAULT_BG_COLOR_DARK_MODE = '#212325'
const DEFAULT_BG_COLOR_LIGHT_MODE = '#FFF'

type AvatarPofileSettingsProps = {
  isOpen: boolean
  close: () => void
}

const AvatarEditor = ({ close }: AvatarPofileSettingsProps) => {
  const t = useTranslation()
  const { colorScheme } = useMantineColorScheme()
  const { downloadAvatar, uploadAvatar, loading } = useAvatarDownloader()
  const ref = useRef<HTMLCanvasElement>(null)
  const [background, setBackground] = useState(
    colorScheme === 'dark' ? DEFAULT_BG_COLOR_DARK_MODE : DEFAULT_BG_COLOR_LIGHT_MODE
  )

  return (
    <Stack p='lg' h='100%'>
      <Stack gap='xs' h='100%'>
        <AvatarPreview
          ref={ref}
          isEditor
          background={background}
          className={clsx('border rounded-full overflow-hidden', { ['border-gray-7']: colorScheme === 'dark' })}
          centerProps={{ zoom: 12, position: [0, -1.2, 0] }}
        />
        <ColorInput placeholder={t('Background Color')} value={background} onChange={setBackground} format='hex' />
        <Center>
          <Button
            variant='transparent'
            c='inherit'
            rightSection={<Icon name='ArrowDown' />}
            onClick={() => ref.current && downloadAvatar(ref.current)}
          >
            {t('Download')}
          </Button>
        </Center>
      </Stack>
      <Flex justify='space-between'>
        <Button onClick={close} variant='outline' w={128}>
          {t('Back')}
        </Button>
        <Button loading={loading} w={128} onClick={() => ref.current && uploadAvatar(ref.current, 0).then(close)}>
          {t('Save')}
        </Button>
      </Flex>
    </Stack>
  )
}

export default AvatarEditor
