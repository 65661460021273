import { useTranslation } from 'summon-ui/intl'
import { Stack, Text, Box, Title, Divider, Button, Flex } from 'summon-ui/mantine'
import { SocialSharingModal } from '@components'
import AvatarPreview from '@modules/avatar'

interface Props {
  isEquipping: boolean
  displayShareAvatar: boolean
  onEquipInventory: () => Promise<void>
  onEquipDone: () => void
}

const Equipping = ({ isEquipping, displayShareAvatar, onEquipDone, onEquipInventory }: Props) => {
  const t = useTranslation()

  return (
    <Stack justify='center' align='center' ta='center' mt='md'>
      <AvatarPreview />
      <Stack gap='0'>
        {isEquipping ? (
          <>
            <Title order={2}>{t('Equipping')}</Title>
            <Text>
              {t('Your avatar is being equipped')}. <br /> {t('Confirmation expected in 3s')}...
            </Text>
          </>
        ) : (
          <>
            <Title order={2}>{t('Success')}</Title>
            <Text>
              {t('Your avatar was successfully equipped')}. <br />
              {t('Close this menu and check it out')}.
            </Text>
          </>
        )}
      </Stack>
      <Box w='100%' bottom={0} left={0} pos={{ base: 'fixed', md: 'absolute' }}>
        <Divider mb='md' />
        <Flex justify='space-between' px='sm' mb='md'>
          <Button variant='outline' miw={100} onClick={onEquipDone}>
            {t('Close')}
          </Button>
          {displayShareAvatar ? (
            <SocialSharingModal variant='avatar' asButton buttonText='Share' />
          ) : (
            <Button onClick={onEquipInventory} disabled loading={isEquipping}>
              {t('Equip Gear')}
            </Button>
          )}
        </Flex>
      </Box>
    </Stack>
  )
}

export default Equipping
