import { useGqlQuery } from '@api/gql/gqlServices'
import { GetTransactionHistoryDocument, TransactionHistoryType, GetTransactionHistoryQuery } from '@generated/generates'
import { HistoryTableElement } from './types'

const useHistory = () => {
  const { data, isLoading, status, refetch } = useGqlQuery<GetTransactionHistoryQuery>(GetTransactionHistoryDocument)

  let history: HistoryTableElement[] = []
  if (data?.viewer?.transactionHistory) {
    history = data.viewer.transactionHistory.map((historyEntrance) => {
      const { type, description, value, txLink, txAddress, createdAt, materials, badges } = historyEntrance
      const isOffChain = type === TransactionHistoryType.OffChain

      return {
        description,
        XP: isOffChain ? value : undefined,
        materials,
        badges,
        onChain: {
          type,
          txLink,
          txAddress // txAddress is optional here
        },
        date: new Date(createdAt)
      }
    })
  }

  return {
    isLoading,
    status,
    refetch,
    history: status === 'success' ? history : null
  }
}

export default useHistory
