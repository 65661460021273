import { GraphQLClient, Variables } from 'graphql-request'
import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
  QueryFunction,
  UseMutationResult,
  MutationOptions,
  UseMutationOptions
} from '@tanstack/react-query'
import { graphqlClient } from '@api/config'

type VariablesUnion<T = Record<string, unknown>> = {
  variables?: T | undefined
  requestHeaders?: HeadersInit | undefined
}

const getQueryNameFromDocument = (document: string): string => {
  // Find the occurrence of 'query' and extract the query name
  const matches = document.match(/query\s+(\w+)/)
  return matches ? matches[1] : ''
}

export const useGqlQuery = <TData>(
  queryDocument: string,
  variables?: VariablesUnion['variables'],
  options?: Omit<UseQueryOptions<TData, unknown, TData, [string, VariablesUnion?]>, 'queryKey' | 'queryFn'>
): UseQueryResult<TData, unknown> => {
  const queryFn: QueryFunction<TData, [string, VariablesUnion?]> = async ({ queryKey }) => {
    const [, queryVariables] = queryKey
    const response = await graphqlClient().request<TData, Variables>(queryDocument, queryVariables)
    return response
  }

  const queryName = getQueryNameFromDocument(queryDocument)
  const queryKey: [string, VariablesUnion?] = variables === undefined ? [queryName] : [queryName, variables]

  return useQuery<TData, unknown, TData, [string, VariablesUnion?]>({ queryKey, queryFn, ...options })
}

interface UseMutationProps<TData, TError, TVariables> {
  mutationHook: (
    client: GraphQLClient,
    options?: UseMutationOptions<TData, TError, TVariables, unknown>
  ) => UseMutationResult<TData, TError, TVariables, unknown>
  options?: MutationOptions<TData, TError, unknown>
}

export const useGqlMutation = <TData, TError, TVariables>({
  mutationHook,
  options
}: UseMutationProps<TData, TError, TVariables>): UseMutationResult<TData, TError, TVariables, unknown> =>
  mutationHook(graphqlClient(), options)
