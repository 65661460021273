import {
  Accordion,
  Box,
  Button,
  Stack,
  Title,
  alpha,
  Text,
  Divider,
  ColorSwatch,
  Group,
  useLocalStorage
} from 'summon-ui/mantine'
import { TENANT_API } from '@config'
import { Hosts } from '@config/enum'
import { useTour } from '@reactour/tour'
import { isDev } from '@utils/isStaging'

interface Tenant {
  name: string
  host: Hosts
}

const TENANTS: Tenant[] = [
  {
    name: 'Game7',
    host: Hosts.G7
  },
  {
    name: 'Game7 Staging',
    host: Hosts.G7Staging
  },
  {
    name: 'Game7 Prisma Staging',
    host: Hosts.G7Staging2
  }
]

const TenantDevTools = () => {
  const [selectedTenant, setSelectedTenant] = useLocalStorage({ key: 'tenant', defaultValue: Hosts.G7Staging })
  const { setIsOpen: setStartTutorial } = useTour()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setLastLevelUpLevel] = useLocalStorage({ key: 'lastLevel', defaultValue: 0 })
  const selectedTenantName = TENANTS.find((tenant) => tenant.host === selectedTenant)?.name || ''

  if (!isDev) return null

  return (
    <div className='fixed top-0 left-1/2 transform -translate-x-1/2 w-[250px] z-[9999]'>
      <Accordion variant='contained'>
        <Accordion.Item value={selectedTenantName} bg={alpha('var(--mantine-color-brand-7)', 0.7)}>
          <Accordion.Control h='25px' bg='transparent' c='white'>
            {selectedTenantName}
          </Accordion.Control>
          <Accordion.Panel bg='black' c='gray.6'>
            <Stack gap='md'>
              <Box>
                <Title order={5} my='4' c='white'>
                  Tenants
                </Title>

                <Stack gap='0' pl='xs'>
                  {TENANTS.map((tenant) => {
                    const isSelectedTenant = selectedTenant === tenant.host

                    return (
                      <Box
                        key={tenant.name}
                        className={isSelectedTenant ? 'text-white' : 'cursor-pointer hover:text-gray-5'}
                        onClick={() => {
                          localStorage.clear()
                          setSelectedTenant(tenant.host)
                          window.location.reload()
                        }}
                      >
                        <Text>
                          {isSelectedTenant && '▶'} {tenant.name}
                        </Text>
                      </Box>
                    )
                  })}
                </Stack>
              </Box>

              <Divider />
              <Box>
                <Title order={5} mb='4' c='white'>
                  Actions
                </Title>
                <Stack gap='xs'>
                  <Button
                    size='compact-xs'
                    maw={150}
                    onClick={() => {
                      setLastLevelUpLevel(0)
                      window.location.reload()
                    }}
                  >
                    Trigger level modal
                  </Button>
                  <Button
                    size='compact-xs'
                    maw={150}
                    onClick={() => {
                      setStartTutorial(true)
                      close()
                    }}
                  >
                    Trigger Tutorial
                  </Button>
                </Stack>
              </Box>
              <Divider />

              <Box>
                <Title order={5} mb='4' c='white'>
                  Config
                </Title>
                <Stack gap='xs' pl='xs'>
                  <Box>
                    <Text c='white'>GraphQL Endpoint</Text>
                    <Text size='xs'>{TENANT_API.graphql}</Text>
                  </Box>
                  <Box>
                    <Text c='white'>Rest Endpoint</Text>
                    <Text size='xs'>{TENANT_API.rest}</Text>
                  </Box>
                  <Group>
                    <Text c='white'>Theme Color</Text>
                    <ColorSwatch color='var(--mantine-color-brand-5)' />
                  </Group>
                </Stack>
              </Box>
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default TenantDevTools
