import { useLocation, useNavigate } from 'react-router-dom'
import { BadgePrize, EnhancedPaper, Icon, TenantImageEnum, useAssetsContext, useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import {
  Button,
  Flex,
  Image,
  Text,
  Stack,
  Title,
  Paper,
  useMantineColorScheme,
  Select,
  TypographyStylesProvider
} from 'summon-ui/mantine'
import { graphqlClient } from '@api/config'
import CategoryCard from '@features/quests/components/CategoryCard'
import { Category, useGetCampaignsQuery } from '@generated/generates'
import QuestCategoryCardGrid from '../components/QuestCategoryCardGrid'
import useQuestsCategories from '../hooks/useQuestCategories'

const AllQuestsCategoriesView = () => {
  const t = useTranslation()
  const navigate = useNavigate()
  const { search } = useLocation()
  const { colorScheme } = useMantineColorScheme()
  const isMaxTablet = useMediaScreen({
    type: 'max',
    breakpoint: 'tablet'
  })
  const isMobile = useMediaScreen({
    type: 'max',
    breakpoint: 'mini'
  })
  const { getTenantImage } = useAssetsContext()
  const questPlaceholder = getTenantImage(TenantImageEnum.QuestPlaceholder)
  const { questsCategories: questsCategoriesData, isCategoriesLoading, featuredCategory } = useQuestsCategories()
  const { data: campaignsData } = useGetCampaignsQuery(graphqlClient())
  const campaignsList =
    campaignsData?.campaigns.map((campaign) => ({ label: campaign.name!, value: campaign.key! })) || []

  const questsCategories = search
    ? questsCategoriesData.filter((category) => category.campaigns.includes(search.replace('?campaign=', '')))
    : questsCategoriesData

  const featuredButtonTitle = () => {
    if (!featuredCategory?.completionPercentage) {
      return 'View'
    }
    if (featuredCategory?.completionPercentage === 0) {
      return t('Get Started')
    } else if (featuredCategory?.completionPercentage === 100) {
      return t('Completed')
    } else {
      return t('Continue')
    }
  }

  const isFeaturedCategoryCompleted = featuredCategory?.completionPercentage === 100

  const handleOpenCategory = (id: string) => () => {
    navigate(id)
  }

  const renderCardElement = (category: Category) => {
    return <CategoryCard key={category.id} category={category} />
  }

  return (
    <Stack gap='lg'>
      <Title order={2}>{t('Featured Quest')}</Title>
      {isCategoriesLoading && (
        <Paper
          className='animate-pulse'
          radius='lg'
          miw={300}
          h={325}
          bg={colorScheme === 'dark' ? 'dark.3' : 'gray.3'}
        />
      )}
      {featuredCategory && (
        <>
          <EnhancedPaper withBorder radius='md' p='lg' withBg>
            <Flex direction={isMaxTablet ? 'column' : 'row'} gap='lg' align='center'>
              <Paper className='overflow-hidden' radius='md'>
                <Image
                  data-testid='featured-category-hero-image'
                  alt='quest'
                  src={featuredCategory.imageUrl || questPlaceholder}
                  radius='md'
                  w={isMaxTablet ? '100%' : 500}
                  onClick={handleOpenCategory(featuredCategory.id)}
                  className='cursor-pointer hover:scale-110 transition-all duration-500'
                />
              </Paper>

              <Stack gap='sm' w={isMaxTablet ? '100%' : '50%'}>
                <Title data-testid='featured-category-name-label' fw='bold' lineClamp={2}>
                  {featuredCategory?.name}
                </Title>
                <Flex gap='xs' pt='xs'>
                  {featuredCategory?.totalXP != null && (
                    <BadgePrize
                      variant='xp'
                      info={`${new Intl.NumberFormat('en-US').format(featuredCategory.totalXP)} XP`}
                    />
                  )}
                  {featuredCategory?.totalCredits != null && (
                    <BadgePrize
                      variant='credits'
                      info={`${new Intl.NumberFormat('en-US').format(featuredCategory.totalCredits)} CREDITS`}
                    />
                  )}
                </Flex>
                <TypographyStylesProvider>
                  <Text
                    {...(!isMaxTablet ? { lineClamp: 5 } : {})}
                    data-testid='featured-category-description-label'
                    dangerouslySetInnerHTML={{ __html: featuredCategory?.description || '' }}
                  />
                </TypographyStylesProvider>
                <Button
                  data-testid='featured-category-open-button'
                  maw={160}
                  onClick={handleOpenCategory(featuredCategory.id)}
                  loading={!featuredCategory}
                  {...(isFeaturedCategoryCompleted ? { variant: 'outline' } : {})}
                  {...(isFeaturedCategoryCompleted ? { c: 'green.9' } : {})}
                  leftSection={isFeaturedCategoryCompleted ? <Icon name='CheckCircle' c='green.9' /> : null}
                >
                  {featuredButtonTitle()}
                </Button>
              </Stack>
            </Flex>
          </EnhancedPaper>
        </>
      )}
      <Flex
        justify='space-between'
        align={isMobile ? '' : 'center'}
        direction={isMobile ? 'column' : 'row'}
        mt='md'
        gap={isMobile ? 'sm' : 0}
      >
        <Title size={30}>{t('Quest Book')}</Title>
        <Select
          defaultValue='all'
          data={[{ label: 'All', value: '' }, ...campaignsList]}
          checkIconPosition='right'
          value={search.replace('?campaign=', '')}
          w={isMobile ? '100%' : 'auto'}
          onChange={(_value, option) =>
            navigate({
              search: option?.value.length ? `?campaign=${option.value}` : ''
            })
          }
        />
      </Flex>

      <QuestCategoryCardGrid
        isLoading={isCategoriesLoading}
        cards={questsCategories}
        renderCardElement={renderCardElement}
      />
    </Stack>
  )
}

export default AllQuestsCategoriesView
