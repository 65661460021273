import { Spinner, TenantImageEnum, useAssetsContext, useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import {
  Center,
  Flex,
  Image,
  Stack,
  Title,
  Text,
  Progress,
  Badge,
  Button,
  TypographyStylesProvider
} from 'summon-ui/mantine'
import { ReadMore, Breadcrumbs } from '@components'
import { useGetCategoryBySlug, useViewer } from '@hooks'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import QuestsContainerList from '../components/QuestsContainerList'

const QuestCategoryDetailsView = ({ urlSlug }: { urlSlug: string }) => {
  const t = useTranslation()
  const { isAuthenticated } = useViewer()
  const isMaxTablet = useMediaScreen({
    type: 'max',
    breakpoint: 'tablet'
  })
  const { open: openConnectModal } = useWeb3Modal()
  const { getTenantImage } = useAssetsContext()
  const NotFoundImage = getTenantImage(TenantImageEnum.NotFound)
  const questPlaceholder = getTenantImage(TenantImageEnum.QuestPlaceholder)
  const { category, isCategoryLoading } = useGetCategoryBySlug({ urlSlug })

  if (isCategoryLoading) {
    return (
      <Center h='100%' w='100%'>
        <Spinner />
      </Center>
    )
  }

  const isCategoryCompleted = category?.completionPercentage === 100

  return category ? (
    <Stack gap='xl' pb='xl'>
      <Breadcrumbs lastText={category?.name || 'Loading'} />
      <Flex direction={isMaxTablet ? 'column' : 'row'} gap='lg' align='center'>
        <Image
          data-testid='category-hero-image'
          alt='quest'
          src={category?.imageUrl || questPlaceholder}
          radius='md'
          w={isMaxTablet ? '100%' : 500}
        />
        <Stack gap='sm' w={isMaxTablet ? '100%' : '50%'}>
          <Title data-testid='category-name-label' fw='bold' lineClamp={2}>
            {category?.name}
          </Title>
          {isCategoryCompleted && (
            <Badge bg='green.9' size='lg'>
              {t('Completed')}
            </Badge>
          )}
          <ReadMore maxHeight={120}>
            <TypographyStylesProvider>
              <Text
                data-testid='category-description-label'
                dangerouslySetInnerHTML={{ __html: category?.description || '' }}
              />
            </TypographyStylesProvider>
          </ReadMore>
          {isAuthenticated ? (
            <Flex align='center' gap='sm'>
              <Progress size='lg' className='w-full' value={category?.completionPercentage || 0} />
              <Title order={4}>{category?.completionPercentage}%</Title>
            </Flex>
          ) : (
            <Button onClick={() => openConnectModal()} maw={160}>
              {t('LOGIN / SIGN UP')}
            </Button>
          )}
        </Stack>
      </Flex>
      <QuestsContainerList categoryId={category.id} />
    </Stack>
  ) : (
    <Center h='100%'>
      <Image src={NotFoundImage} />
    </Center>
  )
}

export default QuestCategoryDetailsView
