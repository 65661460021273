import { Badge, Paper, Stack } from 'summon-ui/mantine'
import { SlotType } from '@modules/inventory/hooks/useAvatarStore'

interface Props {
  className?: string
  image: string
  slotType?: SlotType
  count?: number
  name?: string
}

const MaterialSlot = ({ image, count, name }: Props) => {
  return (
    <Paper withBorder radius='md' maw={150} h={170} p='md'>
      <Stack h='100%' align='center' gap={0}>
        <img className='h-full' src={image} alt='material' />
        <Badge size='sm' variant='light' color={count ? 'green' : 'red'}>
          {count} {name}
        </Badge>
      </Stack>
    </Paper>
  )
}

export default MaterialSlot
