import { useMemo } from 'react'
import { LootdropProgress, EnhancedPaper, TimeIndicator, useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Paper, Flex, Title, Text, Stack, Image, Divider, Box } from 'summon-ui/mantine'
import { LootDrop } from '@generated/generates'
import { useRedeemables } from '@hooks'
import { weAreBetweenDates, isXDaysBeforeDate, calculateLootdropDuration } from '../helpers'
import InfoCard from './InfoCard'
import LootdropReward from './LootdropReward'

interface Props {
  lootdrop: LootDrop
}

const LootdropInfo = ({ lootdrop }: Props) => {
  const t = useTranslation()
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'mini' })
  const {
    name,
    startTime,
    endTime,
    coverImage,
    description,
    completionPercentage,
    multiplier,
    rewardPoolInfo,
    rewards
  } = lootdrop

  const { rewardsRedeemables } = useRedeemables({ redeemed: false })
  const reward = rewardsRedeemables?.find((reward) => reward.lootDropId === lootdrop.id)

  const revealCountdownOnDate = useMemo(() => {
    const isOpen = weAreBetweenDates(startTime, endTime)
    return !isOpen ? isXDaysBeforeDate(7, startTime) : new Date()
  }, [startTime, endTime])

  const renderTimeIndicator = () => (
    <TimeIndicator
      startTime={new Date(startTime)}
      betweenStartEndTimeText={t('Open')}
      revealCountdownOnText={`${t('LootDrop ends in')}`}
      revealCountdownOn={revealCountdownOnDate}
      endTime={new Date(endTime)}
      render={({ indicator }) => (
        <Text c='yellow.6' fw='bold'>
          {indicator}
        </Text>
      )}
    />
  )

  const renderDurationAndTimeInfo = () => {
    return isMobile ? (
      <Flex gap='sm' justify='space-between'>
        <Text>{t('Duration')}:</Text>
        <Text>{calculateLootdropDuration(startTime, endTime)}</Text>
      </Flex>
    ) : (
      <Flex gap='sm'>
        <Text>
          {t('Duration')}: {calculateLootdropDuration(startTime, endTime)}
        </Text>
        <Divider orientation='vertical' size='sm' />
        {renderTimeIndicator()}
      </Flex>
    )
  }

  return (
    <EnhancedPaper withBorder withBg p='lg' radius='md'>
      <Stack gap='lg'>
        <Flex justify='start' align='center' gap='md'>
          <Paper withBorder>
            <Image src={coverImage} w={80} radius='md' />
          </Paper>
          <Stack justify='center' gap='xs'>
            <Title order={2}>{name}</Title>
            {!isMobile && renderDurationAndTimeInfo()}
            {isMobile && renderTimeIndicator()}
          </Stack>
        </Flex>

        {reward && <LootdropReward reward={reward} />}
        {isMobile && renderDurationAndTimeInfo()}
        {completionPercentage != null && (
          <Box py='md'>
            <LootdropProgress value={completionPercentage} />
          </Box>
        )}
        <Flex gap='xs' direction={isMobile ? 'column' : 'row'}>
          <InfoCard
            info={`${multiplier?.toString() ?? '1'}x`}
            description={t('Current Multiplier')}
            tooltipInfo={{
              label: t(
                'This is the multiplier you have earned by completing Game7 levels and LootDrop weekly quests. The higher your multiplier, the greater the chance you have of winning an uncommon, rare, or legendary LootDrop Box.'
              ),
              w: 200
            }}
          />
          <InfoCard
            info={rewardPoolInfo}
            description={t('Available Rewards')}
            tooltipInfo={{
              label: t(
                'Grow the prize pool to 2 million MNT by inviting your friends. The more people who participate, the greater the rewards.'
              ),
              w: 200
            }}
          />
          {rewards?.[0]?.description && (
            <InfoCard
              info={rewards?.[0].description}
              description={t('Current Legendary Reward')}
              tooltipInfo={{
                label: t(
                  'For every additional 25,000 participants, the rewards inside the Legendary LootDrop Box and all other LootDrop Boxes will increase.'
                ),
                w: 200
              }}
            />
          )}
        </Flex>
        <Stack>
          <Title order={2}>{t('About')}</Title>
          <Text dangerouslySetInnerHTML={{ __html: description || '' }} />
        </Stack>
      </Stack>
    </EnhancedPaper>
  )
}

export default LootdropInfo
