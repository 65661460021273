import { useDebouncedState } from 'summon-ui/mantine'
import { useGqlQuery } from '@api/gql/gqlServices'
import {
  CreditsLeaderboard,
  GetCreditsLeaderboardCurrentUserDocument,
  GetCreditsLeaderboardCurrentUserQuery,
  GetCreditsLeaderboardDocument,
  GetCreditsLeaderboardQuery,
  Maybe
} from '@generated/generates'
import { useViewer } from '@hooks'
import { sliceAddress } from '@utils/strings'

const useCreditsLeaderboard = (pageSize: number) => {
  const [currentPage, setCurrentPage] = useDebouncedState(1, 200)
  const { viewer } = useViewer()
  const { data, isLoading, status, refetch } = useGqlQuery<GetCreditsLeaderboardQuery>(GetCreditsLeaderboardDocument, {
    limit: pageSize,
    offset: (currentPage - 1) * pageSize
  })
  const { data: topUsers } = useGqlQuery<GetCreditsLeaderboardQuery>(GetCreditsLeaderboardDocument, {
    limit: 3,
    offset: 0
  })
  const topThreeLeaderboardUsers = [
    topUsers?.getCreditsLeaderboard?.[1],
    topUsers?.getCreditsLeaderboard?.[0],
    topUsers?.getCreditsLeaderboard?.[2]
  ]

  const { data: userLeaderboardData } = useGqlQuery<GetCreditsLeaderboardCurrentUserQuery>(
    GetCreditsLeaderboardCurrentUserDocument,
    {},
    { enabled: !!viewer }
  )

  const handleRetrievePage = (page: number) => {
    setCurrentPage(page)
  }

  return {
    isLoading,
    status,
    refetch,
    leaderboard: data?.getCreditsLeaderboard
      ? buildUpLeaderboardTableData([
          ...(userLeaderboardData && !!userLeaderboardData.getCreditsLeaderboardCurrentUser
            ? [userLeaderboardData.getCreditsLeaderboardCurrentUser as CreditsLeaderboard]
            : []),
          ...(data.getCreditsLeaderboard as CreditsLeaderboard[])
        ])
      : [],
    currentPage,
    topThreeLeaderboardUsers,
    navigateToPage: handleRetrievePage,
    isUserOnLeaderboard: !!userLeaderboardData
  }
}

export default useCreditsLeaderboard

export interface CreditsLeaderboardTableElement {
  rank: Maybe<number> | undefined
  member: {
    walletAddress: Maybe<string> | undefined
    name: Maybe<string> | undefined
    avatarUrl: Maybe<string> | undefined
  }
  credits: Maybe<number> | undefined
}

const buildUpLeaderboardTableData = (leaderboard: Array<CreditsLeaderboard>): Array<CreditsLeaderboardTableElement> => {
  const updatedLeaderboard = [
    ...leaderboard.map((user) => ({
      rank: user.position,
      member: {
        avatarUrl: user.avatarUrl,
        walletAddress: user.walletAddress,
        name: user.username || user.username || user.firstName || sliceAddress(user.walletAddress as string, 5)
      },
      credits: Math.floor(user.creditsAmount || 0)
    }))
  ]

  return updatedLeaderboard
}
