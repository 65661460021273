import { ReactNode } from 'react'
import { Table, Icon, TableColumn, Spinner } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Badge, Flex, Box, Center, Stack, Title, Paper } from 'summon-ui/mantine'
import { EmptyState } from '@components'
import { Badge as BadgeType, CraftingMaterial } from '@generated/generates'
import { sliceAddress } from '@utils/strings'
import { HistoryTableElement, OnChainFieldType } from './types'
import useHistory from './useHistory'

type TableKeys = 'description' | 'XP' | 'materials' | 'badges' | 'onChain' | 'date'

export const TITLE = 'User History'

const HistoryView = () => {
  const t = useTranslation()
  const { history, isLoading, refetch } = useHistory()

  const renderXp = (value: number): ReactNode =>
    value ? <Badge data-testid='history-table-xp-label'>{value}</Badge> : null

  const renderDescription = (description: HistoryTableElement['description']): ReactNode => {
    return (
      <Flex data-testid='history-table-description-label' align='center' justify='left' gap='md' className='w-64'>
        {description}
      </Flex>
    )
  }

  const renderBadges = (badges: BadgeType[]) => {
    return (
      <Box>
        {badges.map((badge, index) => (
          <span data-testid='history-table-badge-label' key={badge.id} className='capitalize'>
            {(index ? ', ' : '') + badge.name}
          </span>
        ))}
      </Box>
    )
  }

  const renderMaterials = (materials: CraftingMaterial[]) => {
    return (
      <Box>
        {materials.map((material, index) => (
          <span data-testid='history-table-materials-label' key={material.id} className='capitalize'>
            {(index ? ', ' : '') + material.name}
          </span>
        ))}
      </Box>
    )
  }

  const renderOnChain = ({ txLink, txAddress }: HistoryTableElement['onChain']): ReactNode => {
    return txLink && txAddress ? (
      <Box className='flex max-w-[100px] gap-2 hover:text-brand'>
        <a data-testid='history-table-onchain-link' href={txLink} target='_blank' rel='noreferrer'>
          {txAddress ? sliceAddress(txAddress) : ''}
        </a>
      </Box>
    ) : null
  }

  const renderDate = (date: HistoryTableElement['date']): ReactNode => {
    return (
      <Box data-testid='history-table-date-label' className='max-w-[100px]'>
        {new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit'
        }).format(date)}
      </Box>
    )
  }

  const columns: TableColumn<HistoryTableElement, TableKeys>[] = [
    {
      name: 'Description',
      key: 'description',
      render: (value) => renderDescription(value as HistoryTableElement['description'])
    },
    {
      name: 'XP',
      key: 'XP',
      render: (value) => renderXp(value as number),
      sort: (a, b) => (a?.XP ?? 0) - (b?.XP ?? 0)
    },
    {
      name: 'Earned Materials',
      key: 'materials',
      render: (value) => renderMaterials(value as CraftingMaterial[])
    },
    {
      name: 'Earned Badges',
      key: 'badges',
      render: (value) => renderBadges(value as BadgeType[])
    },
    {
      name: 'On Chain',
      key: 'onChain',
      render: (value) => renderOnChain(value as OnChainFieldType)
    },
    {
      name: 'Date',
      key: 'date',
      render: (value) => renderDate(value as Date),
      sort: (a, b) => b.date.getTime() - a.date.getTime()
    }
  ]

  const handleRefreshHistoryTable = () => {
    refetch()
  }

  if (isLoading) {
    return (
      <Center h='100%' w='100%'>
        <Spinner />
      </Center>
    )
  }

  return (
    <>
      {history && history.length > 0 ? (
        <Paper withBorder radius='lg' pt='sm' className='overflow-hidden'>
          <Stack className='h-full'>
            <Flex align='center' justify='space-between' className='h-14 px-6'>
              <Title data-testid='history-title-label' order={4}>
                {t(TITLE)}
              </Title>
              <Box>
                <Icon
                  data-testid='history-refresh-button'
                  name='RefreshCw04'
                  size='md'
                  onClick={handleRefreshHistoryTable}
                />
              </Box>
            </Flex>
            <Table<HistoryTableElement, TableKeys, TableKeys>
              data={history}
              dataAlignment={{ description: 'left', XP: 'center', materials: 'center', badges: 'center' }}
              columns={columns}
              stickyConf={{
                stickyHeader: true,
                stickyHeaderOffset: 0
              }}
            />
          </Stack>
        </Paper>
      ) : (
        <Center h='100%' w='100%'>
          <EmptyState
            title={t('No history found')}
            description={t('We can’t find any activity, come back later')}
            testId='history'
          />
        </Center>
      )}
    </>
  )
}

export default HistoryView
