/* eslint-disable react/no-unknown-property */
import { memo, Suspense } from 'react'
import { Spinner } from 'summon-ui'
import { useGLTF } from '@react-three/drei'
import { getNodeData } from '@utils/object3d'

interface IMeshProps {
  children?: React.ReactNode
  uri: string
  color?: string | null | undefined
  renderOrder?: number
}

const Mesh = memo(({ children, uri, color, renderOrder = 1 }: IMeshProps) => {
  const { nodes, materials } = useGLTF(uri)
  // @ts-ignore
  const meshes = getNodeData(nodes, materials)

  return (
    <Suspense fallback={<Spinner />}>
      {meshes?.map((mesh) => {
        return (
          <mesh
            renderOrder={renderOrder}
            frustumCulled={false}
            castShadow={true}
            name={mesh.name}
            key={mesh.name}
            geometry={mesh.geometry}
            material={mesh.material}
            position={mesh.position}
            rotation={mesh.rotation}
          >
            {!!color && <meshStandardMaterial {...mesh.material} color={color} />}
          </mesh>
        )
      })}
      {children}
    </Suspense>
  )
})

Mesh.displayName = 'Mesh'

export default Mesh
