import { useState } from 'react'
import { useTranslation } from 'summon-ui/intl'
import { Modal, Button, Box } from 'summon-ui/mantine'
import { SocialConnect } from '@components'
import { SocialType } from '@components/SocialConnect/types'
import TermsLootdrop from '@features/lootdrops/components/TermsLootdropModal'
import ActionEmailModal from '@features/quests/components/ActionEmailModal'
import ActionSCIDModal from '@features/quests/components/ActionSCIDModal'
import ActionSolanaModal from '@features/quests/components/ActionSolanaModal'
import ActionUsernameModal from '@features/quests/components/ActionUsernameModal'
import CompletedAnimation from '@features/quests/components/CompletedAnimation'
import Telegram from '@features/quests/components/cta/telegram/Telegram'
import Typeform from '@features/quests/components/cta/typeform/Typeform'
import useCheckTaskCompletion from '@features/quests/hooks/useCheckTaskCompletion'
import useTaskCompletedAnimation from '@features/quests/hooks/useTaskCompletedAnimation'
import { TaskWithQuestId } from '@features/quests/types'
import { TaskActionType } from '@generated/generates'
import { useViewer } from '@hooks'
import ActionButtonCheck from './ActionButtonCheck'
import styles from './actionButton.module.css'

// TODO: New Refactor code
const BUTTON_WIDTH = 145
//

// Hack to show pending on this specific task
const PENDING_STATE_TASK_ID = 'bee34375-eb75-4bab-9de2-942a60e30934'

type Props = {
  task: TaskWithQuestId
  testId: string
  disabled?: boolean
  lootdropId?: string | undefined
  userAcceptedLootdropTerms?: boolean
}

const ActionButton = ({ task, testId, disabled, lootdropId, userAcceptedLootdropTerms }: Props) => {
  const t = useTranslation()
  const { viewer } = useViewer()
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false)
  const [isUsernameModalOpen, setIsUsernameModalOpen] = useState(false)
  const [isSolanaModalOpen, setIsSolanaModalOpen] = useState(false)
  const [isSCIDModalOpen, setIsSCIDModalOpen] = useState(false)
  const [isLootdropModalOpen, setIsLootdropModalOpen] = useState(false)

  const {
    checkTaskCompletionMutation,
    isCheckTaskCompletionLoading,
    socialConnect,
    setSocialConnect,
    checkStatus,
    isCheckStatusLoading,
    isUpdatingUserSettings
  } = useCheckTaskCompletion({ task })

  const startTask = () => {
    switch (task.actionType) {
      case TaskActionType.DiscordLogin:
        viewer?.discordUsername
          ? checkTaskCompletionMutation({ questId: task.questId as string, taskId: task.id })
          : setSocialConnect(SocialType.Discord)
        break
      case TaskActionType.AddEmail:
        setIsEmailModalOpen(true)
        break
      case TaskActionType.AddUsername:
        setIsUsernameModalOpen(true)
        break
      case TaskActionType.SolanaWallet:
        setIsSolanaModalOpen(true)
        break
      case TaskActionType.Scid:
        setIsSCIDModalOpen(true)
        break
      case TaskActionType.TwitterLogin:
        viewer?.twitterUsername
          ? checkTaskCompletionMutation({ questId: task.questId as string, taskId: task.id })
          : setSocialConnect(SocialType.Twitter)
        break
      case TaskActionType.VisitSite:
        window.open(task?.inputData as string, '_blank')
        checkTaskCompletionMutation({ questId: task.questId as string, taskId: task.id })
        break
      default:
        break
    }
  }

  const isPendingTask = task.id === PENDING_STATE_TASK_ID
  const { showXP } = useTaskCompletedAnimation({ task })

  if (lootdropId && !userAcceptedLootdropTerms) {
    return (
      <>
        <Button onClick={() => setIsLootdropModalOpen(true)} miw={145} disabled={disabled}>
          {t('Start')}
        </Button>
        <TermsLootdrop
          lootdropId={lootdropId}
          open={isLootdropModalOpen}
          onClose={() => setIsLootdropModalOpen(false)}
        />
      </>
    )
  }

  // TODO: Incremental refactoring. Here the new refactored code takes preference over the rest
  if (task.actionType === TaskActionType.TelegramConnect) {
    return (
      <Box w={BUTTON_WIDTH}>
        <Telegram isCompleted={task.isCompleted} isDisabled={disabled} />
      </Box>
    )
  }
  if (task.actionType === TaskActionType.Typeform) {
    return (
      <Box w={BUTTON_WIDTH}>
        <Typeform
          typeformId={task.inputData as string}
          userId={viewer?.id as string}
          isCompleted={task.isCompleted}
          isDisabled={disabled}
        />
      </Box>
    )
  }
  // Back to the old code

  return (
    <>
      {task.actionType === TaskActionType.AddEmail && (
        <ActionEmailModal opened={isEmailModalOpen} onClose={() => setIsEmailModalOpen(false)} />
      )}

      {task.actionType === TaskActionType.AddUsername && (
        <ActionUsernameModal opened={isUsernameModalOpen} onClose={() => setIsUsernameModalOpen(false)} />
      )}

      {task.actionType === TaskActionType.SolanaWallet && (
        <ActionSolanaModal opened={isSolanaModalOpen} onClose={() => setIsSolanaModalOpen(false)} />
      )}

      {task.actionType === TaskActionType.Scid && (
        <ActionSCIDModal
          opened={isSCIDModalOpen}
          onClose={() => setIsSCIDModalOpen(false)}
          featureId={task?.featureId}
        />
      )}

      {task.isCompleted ? (
        <>
          <Button
            variant='outline'
            c='green.8'
            className='overflow-visible'
            classNames={{ root: styles.completedButtonRoot }}
            miw={145}
          >
            {!!task.earnXP && <CompletedAnimation show={showXP} data={task.earnXP} />}
            {t('Completed')}
          </Button>
        </>
      ) : task.actionType ? (
        <Button
          data-testid={testId}
          onClick={startTask}
          loading={isUpdatingUserSettings || isCheckTaskCompletionLoading}
          miw={145}
          disabled={disabled}
        >
          {t('Start')}
        </Button>
      ) : task.showManualCheckButton ? (
        <ActionButtonCheck task={task} checkStatus={checkStatus} />
      ) : isPendingTask ? (
        <Button disabled miw={125} className='hidden'>
          {t('Pending')}
        </Button>
      ) : (
        <Button variant='outline' onClick={checkStatus} loading={isCheckStatusLoading} miw={125} disabled={disabled}>
          {t('Check Status')}
        </Button>
      )}

      <Modal className='max-w-[400px]' opened={!!socialConnect} onClose={() => setSocialConnect(null)}>
        <SocialConnect type={socialConnect as SocialType} close={() => setSocialConnect(null)} />
      </Modal>
    </>
  )
}

export default ActionButton
